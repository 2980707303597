import React from 'react'
import { ImgWrapper, PanelWrapper } from './styles'
import PropTypes from 'prop-types'

const Panel = ({
  header,
  content,
  collapsibleKey,
  customClassName = '',
  onChange,
  showArrow = false,
  isActive,
  background,
  expandIcon
}) => {
  return (
    <PanelWrapper
      className={`accordian-item ${customClassName} ${
        isActive ? 'active' : 'collapse'
      }`}
      background={background}
    >
      <div className='panel-flex'>
        <div
          className={`accordian-header ${isActive ? 'active' : ''}`}
          onClick={() => onChange(collapsibleKey)}
        >
          {header}
        </div>
        {showArrow ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {expandIcon || (
              <ImgWrapper src='/img/chevron-down.svg' isActive={isActive} />
            )}
          </div>
        ) : null}
      </div>
      <div className={`accordian-content ${isActive ? 'active' : 'collapse'}`}>
        <div className='accordian-text'>{content}</div>
      </div>
    </PanelWrapper>
  )
}
Panel.propTypes = {
  /* this shows the main heading of the list */
  header: PropTypes.any,
  /* this is the children inside each header */
  content: PropTypes.any,
  /* this key is required as it gives information about which option is selected */
  collapsibleKey: PropTypes.string.isRequired,
  /* sets the className */
  customClassName: PropTypes.string,
  /* callback function returning event */
  onChange: PropTypes.func,
  /* this boolean value sets whether or not the show the arrow in the header */
  showArrow: PropTypes.bool,
  isActive: PropTypes.bool,
  background: PropTypes.string
}
Panel.defaultProps = {
  header: '',
  content: '',
  collapsibleKey: '',
  customClassName: '',
  showArrow: true,
  isActive: false,
  background: '#fafafa',
  expandIcon: false
}
export default Panel
