const actionTypes = Object.freeze({
	SAVE_USER_ID: "SAVE_USER_ID",
	SAVE_WORKSPACE_ID: "SAVE_WORKSPACE_ID",
	CLEAR_USER_ID: "CLEAR_USER_ID",
	CLEAR_WORKSPACE_ID: "CLEAR_WORKSPACE_ID",
	CLEAR_STORE: "CLEAR_STORE",
});

const saveUserIdAction = (userId) => ({
	type: actionTypes.SAVE_USER_ID,
	payload: userId,
});

const saveWorkspaceIdAction = (workspaceId) => ({
	type: actionTypes.SAVE_WORKSPACE_ID,
	payload: workspaceId,
});

const clearUserIdAction = () => ({
	type: actionTypes.CLEAR_USER_ID,
});

const clearWorkspaceIdAction = () => ({
	type: actionTypes.CLEAR_WORKSPACE_ID,
});

const clearStore = () => ({
	type: actionTypes.CLEAR_STORE,
});

const actions = {
	saveUserIdAction,
	saveWorkspaceIdAction,
	clearWorkspaceIdAction,
	clearUserIdAction,
	clearStore,
};

export { actions, actionTypes };
