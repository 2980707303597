import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputSection, Wrapper, NumberInputSection } from './Input.styled'
import { TEXT_INPUT } from '../Input/constants'

const NumberInput = ({
  className,
  value,
  label,
  onChange,
  min,
  max,
  step,
  disabled,
  showArrows,
  defaultValue,
  prefix,
  suffix,
  size,
  horizontal
}) => {
  const [focus, setFocus] = useState(false)
  const handleFocus = () => {
    setFocus(true)
  }
  const handleBlur = () => {
    setFocus(false)
  }
  const handleChange = (e) => {
    e.key === 'e' && e.preventDefault()
    if (min === 0 && parseInt(e.target.value) < min) e.target.value = min
    if (max && e.target.value > max) e.target.value = max
    onChange && onChange(e)
  }

  return (
    <Wrapper className={className} horizontal={horizontal}>
      {label && <label className='make-ui-number-input-label'>{label}</label>}

      <InputSection
        size={size}
        className={`make-ui-number-input-wrapper ${
          focus ? `make-ui-number-input-wrapper-focus` : ''
        }`}
        disabled={disabled}
      >
        {prefix && <span className='make-ui-text-number-prefix'>{prefix}</span>}
        <NumberInputSection
          type='number'
          className='make-ui-number-input'
          value={value}
          size={size}
          min={min}
          max={max}
          step={step}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === '.') {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          onChange={handleChange}
          disabled={disabled}
          showArrows={showArrows}
          defaultValue={defaultValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {suffix && <span className='make-ui-text-number-suffix'>{suffix}</span>}
      </InputSection>
    </Wrapper>
  )
}
NumberInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  showArrows: PropTypes.bool,
  defaultValue: PropTypes.any,
  horizontal: PropTypes.bool
}
NumberInput.defaultProps = {
  step: 1,
  disabled: false,
  showArrows: false,
  className: `make-ui-number-input-${TEXT_INPUT.SIZE.DEFAULT}`,
  horizontal: true
}

export default NumberInput
