import { actions } from "../store/actions";
import localStore from "./index";

export const storeSyncMiddleware = (store) => (next) => (action) => {

	const {
		currentUserId,
		workSpaceReducer: { workspaceId },
	} = store.getState();

	localStore.dispatch(actions.saveWorkspaceIdAction(workspaceId));
	localStore.dispatch(actions.saveUserIdAction(currentUserId));

	return next(action);
};

export const getUserId = () => {
	const {
		info: { userId },
	} = localStore.getState();

	return userId;
};

export const getWorkspaceId = () => {
	const {
		info: { workspaceId },
	} = localStore.getState();
	return workspaceId;
};
