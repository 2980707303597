import { actionTypes } from "./actions";

const initialState = {
    userId: '',
    workspaceId: ''
}

const originalState = {...initialState}

const InfoReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.SAVE_USER_ID: {
            return {
                ...state,
                userId: payload
            }
        }

        case actionTypes.SAVE_WORKSPACE_ID: {
            return {
                ...state,
                workspaceId: payload
            }
        }
            
        case actionTypes.CLEAR_USER_ID: {
            return {
                ...state,
                userId: ''
            }
        }

        case actionTypes.CLEAR_WORKSPACE_ID:{
            return {
                ...state,
                workspaceId: ''
            }
        }

        case actionTypes.CLEAR_STORE: {
            return {
                ...state,
                ...originalState
            }
        }
    
        default:
            return state
    }
}

export default InfoReducer