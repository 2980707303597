// import { Cross } from 'assets/icons/index'
import styled from 'styled-components'
import { modal } from '../../utils/styles/theme'

export const ModalWrapper = styled.div`
  width: ${(props) => props.width || '100%'};
  max-width: 900px;
  min-width: 100px;
  height: auto;
  position: fixed;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: ${modal.MODAL_BG_COLOR};
  z-index: 6;
  @media (max-width: 968px) {
    max-width: 60%;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  > header {
    color: #000000d9;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px -2px 34px;
  background-color: rgb(255, 255, 255);
  button {
    margin: 0 10px;
  }
`

/* Mask */
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${modal.OVERLAY_BG_COLOR};
  opacity: 0.5;
  z-index: 5;
`
export const Body = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
`
