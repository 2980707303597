import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TEXT_INPUT } from './constants'
import { Wrapper, TextInput, InputSection } from './Input.styled'

const Input = ({
  label,
  size,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  onChange,
  id,
  disabled,
  value,
  className,
  placeholder,
  defaultValue,
  prefix,
  suffix,
  horizontal,
  onEnter
}) => {
  const [focus, setFocus] = useState(false)
  const handleFocus = (e) => {
    setFocus(true)
    onFocus && onFocus(e)
  }
  const handleBlur = (e) => {
    setFocus(false)
    onBlur && onBlur(e)
  }
  const handleKeyDown = (e) => {
    if (onEnter && e.key === 'Enter') {
      onEnter(e.currentTarget.value)
    }
  }
  return (
    <Wrapper className={className} horizontal={horizontal}>
      {label && <label className='make-ui-text-input-label'>{label}</label>}
      <InputSection
        size={size}
        className={`make-ui-text-input-wrapper ${
          focus ? `make-ui-text-input-wrapper-focus` : ''
        }`}
      >
        {prefix && <span className='make-ui-text-input-prefix'>{prefix}</span>}
        <TextInput
          id={id}
          type='text'
          className='make-ui-text-input'
          size={size}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          // prefix={prefix}
          // suffix={suffix}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onKeyDown={handleKeyDown}
        />
        {suffix && <span className='make-ui-text-input-suffix'>{suffix}</span>}
      </InputSection>
    </Wrapper>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  size: PropTypes.oneOf([
    TEXT_INPUT.SIZE.BIG,
    TEXT_INPUT.SIZE.SMALL,
    TEXT_INPUT.SIZE.DEFAULT
  ]),
  horizontal: PropTypes.bool,
  onEnter: PropTypes.func
}
Input.defaultProps = {
  label: '',
  size: TEXT_INPUT.SIZE.DEFAULT,
  id: '1',
  disabled: false,
  className: '',
  placeholder: '',
  suffix: false,
  prefix: false,
  defaultValue: '',
  horizontal: false,
  onEnter: () => {}
}

export default Input
