import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DefaultRadio, StyledRadioGroup } from './styles'

export const RadioInput = ({
  defaultChecked,
  setCurrentCheckedValue,
  disabled,
  children,
  className,
  value,
  groupName,
  handleChange,
  id,
  variant
}) => {
  const handleRadioChange = (e) => {
    if (handleChange && typeof handleChange === 'function') handleChange(e)

    setCurrentCheckedValue && setCurrentCheckedValue(e.target.value)
  }

  return (
    <DefaultRadio className={className} variant={variant}>
      <input
        type='radio'
        checked={defaultChecked}
        className={disabled ? 'disabled' : ''}
        value={value}
        id={id}
        name={groupName}
        onChange={handleRadioChange}
      />
      <label htmlFor={id}>{children}</label>
    </DefaultRadio>
  )
}
RadioInput.proptype = {
  /* id for the input */
  id: PropTypes.any.isRequired,
  /* The label beside the radio input */
  children: PropTypes.string,
  /* Specifies list of class names */
  className: PropTypes.string,
  /* determines whether the radio input should be checked when component loads initially */
  defaultChecked: PropTypes.bool,
  /* disables the radio input */
  disabled: PropTypes.bool,
  /* sets the value of the radio input */
  value: PropTypes.any,
  /* sets the name property, which makes sure only one radio input in a 'group' is selected at a time */
  groupName: PropTypes.string,
  /* Sets the current checked value in the parent state i.e in parent Radio Group */
  setCurrentCheckedValue: PropTypes.any,
  /* Callback function which is triggered when a radio input is checked or unchecked */
  handleChange: PropTypes.func
}

RadioInput.defaultProps = {
  disabled: false,
  children: null,
  className: 'make-ui-radio-input-wrapper',
  handleChange: (e) => e
}
export default RadioInput

export const RadioGroup = ({
  groupName,
  defaultValue,
  className,
  children,
  handleChange,
  label,
  variant,
  alignment
}) => {
  const [currentCheckedValue, setCurrentCheckedValue] = useState(defaultValue)
  const newChildren = children.map((child, _idx) => {
    let newChild = false
    if (child.props.value === currentCheckedValue)
      newChild = React.cloneElement(child, {
        key: _idx,
        groupName,
        setCurrentCheckedValue,
        defaultChecked: true,
        variant
      })
    else {
      newChild = React.cloneElement(child, {
        key: _idx,
        groupName,
        setCurrentCheckedValue,
        defaultChecked: false,
        variant
      })
    }
    return newChild
  })

  return (
    <StyledRadioGroup
      onChange={handleChange}
      className={className}
      variant={variant}
      alignment={alignment}
    >
      {label && <label>{label}</label>}
      <div>{newChildren}</div>
    </StyledRadioGroup>
  )
}

RadioGroup.prototype = {
  /* Passes the name property to its children which can only be RadioInput Component */
  groupName: PropTypes.string.isRequired,
  /* Specifies list of class names */
  className: PropTypes.string,
  /* Matches this value with the value of one of its child Radio Input, so that, that child has its value set to true when the component loads initially */
  defaultValue: PropTypes.any,
  /* An array of RadioInput Component */
  children: PropTypes.any,
  /* Callback function which is triggered when a radio input is checked or unchecked */
  handleChange: PropTypes.func,
  /* label for the input */
  label: PropTypes.string,
  /* Whether field is required */
  alignment: PropTypes.string
}

RadioGroup.defaultProps = {
  className: 'make-ui-radio-group-wrapper',
  handleChange: (e) => e,
  alignment: 'horizontal'
}
