import firebase from "./firebase";
export class AuthService {
  constructor() {
    // Variables
    this.firebase = firebase;
    this.auth = firebase.auth();
    this.database = firebase.database();
    this.storage = firebase.storage();
    this.functions = firebase.functions();
    this.user = null;
    this.loadedUser = false;
    this.waitingQueue = [];

    // Methods
    this.getCurrentUserAsync = this.getCurrentUserAsync.bind(this);
    this.getTokenAsync = this.getTokenAsync.bind(this);
    this.getFirebaseInstance = this.getFirebaseInstance.bind(this);
    this.setFirebaseInstance = this.setFirebaseInstance.bind(this);
    this.removeFirebaseInstance = this.removeFirebaseInstance.bind(this);
    this.getAuth = this.getAuth.bind(this);
    this.getDatabase = this.getDatabase.bind(this);
    this.getFunctions = this.getFunctions.bind(this);
    this.getStorage = this.getStorage.bind(this);

    this.auth.onAuthStateChanged((user) => {
      this.user = user;
      this.loadedUser = true;
      this.waitingQueue.map((f) => typeof f === "function" && f()); //wait for user load
      this.waitingQueue = [];
    });
  }

  getFirebaseInstance() {
    return this.firebase;
  }

  getAuth() {
    return this.auth;
  }

  getDatabase() {
    return this.database;
  }

  getFunctions() {
    return this.functions;
  }

  getStorage() {
    return this.storage;
  }

  setFirebaseInstance(firebase) {
    this.firebase = firebase;
    this.auth = firebase.auth();
    this.database = firebase.database();
    this.storage = firebase.storage();
    this.functions = firebase.functions();
  }

  removeFirebaseInstance() {
    this.firebase = null;
    this.auth = null;
    this.database = null;
    this.storage = null;
    this.functions = null;
  }

  getCurrentUserAsync() {
    return new Promise((resolve) => {
      if (this.loadedUser) {
        resolve(this.user);
      } else {
        this.waitingQueue.push(() => {
          resolve(this.user);
        });
      }
    });
  }

  async getTokenAsync() {
    try {
      const currentUser = await this.getCurrentUserAsync();
      if (!currentUser) throw new Error("No User Found");

      const token = await currentUser.getIdToken(true);
      if (!token) throw new Error("Could not get token");

      return token;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
