import { css } from 'styled-components'

export const colors = {
  PRIMARY_COLOR: '#0e64ff',
  SECONDARY_COLOR: '#ECF3FF',
  HOVERCOLOR: '#4889ff',
  PRIMARY_DISABLE: '#85b0ff',
  BG_COLOR: '#fafafa',
  DISABLED_TEXT: 'rgba(0,0,0,0.25)'
}
export const text = {
  ACTIVE_BLUE: '#0e64ff',
  TEXT_COLOR_SECONDARY: '#88888E',
  PRIMARY_TEXT: '#303030',
  TEXT_BOLD: '#101218'
}

export const colorPalatte = {
  WHITE: '#FFFFFF',
  LIGHT_GREY: '#fafafa',
  BLACK: '#000000'
}

export const shadow = {
  shadow: '0px 4px 4px rgba(221, 221, 221, 0.25)'
}

export const buttonTheme = {
  HOVER: colors.HOVERCOLOR,
  PRIMARY: colors.PRIMARY_COLOR,
  SECONDARY: colors.SECONDARY_COLOR,
  ALTERNATEHOVER: 'rgba(207, 224, 255, 0.2)',
  ALTERNATEACTIVE: 'rgba(236, 243, 255, 0.25)',
  DEFAULTDISABLE: colors.PRIMARY_DISABLE
}

export const switchButtonTheme = {
  ACTIVE_COLOR: colors.PRIMARY_COLOR,
  BGCOLOR: colorPalatte.LIGHT_GREY
}

export const avatar = {
  BGCOLOR: colors.SECONDARY_COLOR,
  TEXTCOLOR: colors.PRIMARY_COLOR
}

export const accordian = {
  ACTIVE_COLOR: '#fffff'
}

export const checkbox = {
  BORDER: '#959595',
  ACTIVE: colors.PRIMARY_COLOR
}

export const switchTheme = {
  BG_COLOR: '#0ABA61',
  PRIMARY: colors.PRIMARY_COLOR,
  SECONDARY: colors.SECONDARY_COLOR
}

export const input = {
  FOCUS_COLOR: '#0e64ff'
}

export const radio = {
  BORDER: '#959595',
  HOVER: '#0e64ff'
}

export const tabs = {
  ACTIVE_BORDER: colors.PRIMARY_COLOR,
  TEXT_COLOR_ACTIVE: text.ACTIVE_BLUE,
  TEXT_COLOR_INACTIVE: text.TEXT_COLOR_SECONDARY
}

export const modal = {
  MODAL_BG_COLOR: '#ffffff',
  OVERLAY_BG_COLOR: '#000000',
  CROSS_ICON_BG_COLOR: '#ffffff'
}

export const dropdown = {
  HOVER_COLOR: colors.PRIMARY_COLOR
}

export const sliderWithInput = {
  SLIDER_COLOR: colors.PRIMARY_COLOR
}

export const hexColor = {
  f: '#ffffff',
  black: '#101218',
  lightgrey: '#fafafa',
  darkgrey: '#959595',
  text: '#303030',
  red: ' #C5292A',
  lightRed: '#f9e9e9',
  blue: '#0e64ff',
  lightBlue: '#ECF3FF',
  bluehover: '#4889ff',
  green: '#0ABA61',
  lightGreen: '#E3F7ED',
  Yellow: '#FBBF2E',
  lightYellow: '#FFF3D6'
}

export const ELEMENT_SPACE = 15

export const LABEL_MARGIN = css`
  margin: 5px 0 10px 0;
`

export const BUTTON_MARGIN = 10
