import styled from 'styled-components'
import { avatar } from '../../utils/styles/theme'
export const AvatarStyled = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;

  background-color: ${avatar.BGCOLOR};

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  p {
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: ${avatar.TEXTCOLOR};
    margin: 0;
  }
`
