import styled, { css } from 'styled-components'
import { HEADER_LABEL } from '../../utils/styles/common'
import { checkbox, ELEMENT_SPACE, hexColor } from '../../utils/styles/theme'

const DEFAULT_STYLE = css`
  [type='checkbox'] {
    display: none;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  label {
    padding-left: 28px;
    position: relative;
    font-size: 13px;
    line-height: 22px;
    color: rgb(79, 79, 79);
    margin: 0;
    cursor: pointer;
    display: block;

    &:before {
      position: absolute;
      width: 16px;
      height: 16px;
      border: 1px solid ${checkbox.BORDER};
      left: 0;
      top: 3px;
      content: '';
      border-radius: 5px;
      transition: 0.3s ease;
    }
    &:after {
      position: absolute;
      width: 10px;
      height: 9px;
      background-image: url(/img/blue-check.svg);
      background-repeat: no-repeat;
      background-size: contain;
      left: 4px;
      top: 8px;
      content: '';
      border-radius: 50%;
      transition: 0.3s ease;
      transform: scale(0);
    }
    &:hover {
      &:before {
        border: 1px solid ${checkbox.ACTIVE};
      }
    }
  }

  input[type='checkBox']:checked + label {
    &:before {
      border: 1px solid ${checkbox.ACTIVE};
    }
    &:after {
      transform: scale(1);
    }
  }
`

const BUTTON_STYLE = css`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 2px;

  > div {
    input {
      display: none;
    }

    label {
      padding: 10px;
      margin: 0;
      cursor: pointer;
      line-height: 110%;
      transition: 0.3s ease;
      font-size: 14px;
      color: rgb(79, 79, 79);
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
      svg {
        transition: 0.3s ease;
        fill: #4a4a4a;
      }

      &:hover {
        color: ${hexColor.blue};
        svg {
          fill: ${hexColor.blue};
        }
      }
    }

    input:checked + label {
      background-color: ${hexColor.lightBlue};
      color: ${hexColor.blue};
      svg {
        fill: ${hexColor.blue};
      }
    }
  }
`
export const CustomcheckBox = styled.div`
  ${DEFAULT_STYLE}
`

export const GroupWrapper = styled.div`
  display: inline-flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  gap: ${({ horizontal, variant }) =>
    horizontal && variant !== 'button' ? '10px' : 0};
  ${({ variant }) => (variant === 'button' ? BUTTON_STYLE : DEFAULT_STYLE)}
`
export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: ${ELEMENT_SPACE}px 0;
  > label {
    ${HEADER_LABEL}
  }
`
