import React from 'react'
import PropTypes from 'prop-types'
import { ButtonWrapper } from './styles'

const HrefJSX = ({
  size,
  active,
  label,
  href,
  children,
  disabled,
  saving,
  saved,
  Icon,
  ...props
}) => {
  return (
    <a
      onClick={props.onClick}
      href={href}
      className={[
        'button',
        `button--${size}`,
        `${active ? 'button--active' : ''}`,
        `${disabled ? 'button--disabled' : ''}`,
        `${saving ? 'button--saving' : ''}`,
        `${saved ? 'button--saved' : ''}`
      ].join(' ')}
      {...props}
    >
      {Icon && Icon}
      {children && <span className='button--text'>{children}</span>}
    </a>
  )
}

const ButtonJSX = ({
  size,
  active,
  label,
  href,
  children,
  disabled,
  saving,
  saved,
  Icon,
  ...props
}) => {
  return (
    <button
      type='button'
      className={[
        'button',
        `button--${size}`,
        `${active ? 'button--active' : ''}`,
        `${disabled ? 'button--disabled' : ''}`,
        `${saving ? 'button--saving' : ''}`,
        `${saved ? 'button--saved' : ''}`
      ].join(' ')}
      {...props}
    >
      <div className='button--text'>
        <div className='icon-section'>
          {Icon && Icon}
          {children}
        </div>
      </div>
    </button>
  )
}

export const Button = ({
  size,
  active,
  label,
  href,
  children,
  disabled,
  saving,
  saved,
  Icon,
  unstyled,
  variant,
  className,
  ...props
}) => {
  return (
    <ButtonWrapper
      active={active}
      unstyled={unstyled}
      variant={variant}
      Icon={Boolean(Icon)}
      ref={props.mainref}
      child={children}
      className={className}
    >
      {href ? (
        <HrefJSX
          size={size}
          active={active}
          label={label}
          href={href}
          children={children}
          disabled={disabled}
          saving={saving}
          saved={saved}
          Icon={Icon}
          {...props}
        />
      ) : (
        <ButtonJSX
          size={size}
          active={active}
          label={label}
          href={href}
          children={children}
          disabled={disabled}
          saving={saving}
          saved={saved}
          Icon={Icon}
          {...props}
        />
      )}
    </ButtonWrapper>
  )
}

Button.propTypes = {
  active: PropTypes.bool,
  /* How large should the button be? */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'default']),
  /* Button contents */
  label: PropTypes.string,
  /* href */
  href: PropTypes.string,
  /* Button states */
  disabled: PropTypes.bool,
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'icon',
    'default',
    'alternate',
    'group'
  ])
}

Button.defaultProps = {
  size: 'default',
  disabled: false,
  saving: false,
  saved: false,
  active: false,
  Icon: undefined,
  unstyled: false,
  variant: 'default',
  className: 'makeui-button'
}

export default Button
