import styled, { css } from 'styled-components'
import { colors, ELEMENT_SPACE, LABEL_MARGIN } from '../../utils/styles/theme'
import { TEXT_INPUT } from '../Input/constants'

const Wrapper = styled.div`
  display: flex;
  /* flex: 1; */
  box-sizing: border-box;
  margin: ${ELEMENT_SPACE}px 0;
  ${({ horizontal }) =>
    horizontal
      ? css`
          align-items: center;
        `
      : css`
          width: 100%;
          flex-direction: column;
        `}
  .make-ui-number-input-label {
    font-size: 14px;
    line-height: 22px;

    ${({ horizontal }) =>
      horizontal
        ? css`
            margin: 0 10px 0 0;
          `
        : css`
            ${LABEL_MARGIN}
          `}
    min-width: 10px;
    font-weight: normal;
    color: rgb(48, 48, 48);
    font-family: Inter, sans-serif;
  }
  &.makeui-no-margin {
    margin: 0px 0 !important;
  }
`
const NumberInputSection = styled.input`
  padding: 0;
  margin: 0;
  border: none !important;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: #000000d9;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  &:disabled {
    cursor: not-allowed;
    color: ${colors.DISABLED_TEXT};
    background-color: #f0f3f4;
  }
  ${(props) =>
    !props.showArrows &&
    `
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      &[type='number'] {
          -moz-appearance: textfield;
      }
  `}
`

const InputSection = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  box-sizing: border-box;

  &.make-ui-number-input-wrapper-focus {
    border: 1px solid #0e64ff;
  }
  ::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
    box-sizing: border-box;
  }

  .make-ui-text-number-prefix {
    margin-right: 4px;
  }

  .make-ui-text-number-suffix {
    margin-left: 4px;
  }

  .make-ui-text-number-prefix,
  .make-ui-text-number-suffix {
    display: flex;
    flex: none;
    align-items: center;
    box-sizing: border-box;
    /* opacity: 0.4; */
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f0f3f4;
      cursor: not-allowed;
    `}
  ${({ size }) => {
    switch (size) {
      case TEXT_INPUT.SIZE.BIG: {
        return css`
          height: 36px;
          padding: 8px 12px;
        `
      }
      case TEXT_INPUT.SIZE.SMALL: {
        return css`
          height: 32px;
          padding: 5px;
          min-width: 0;
        `
      }
      default: {
        return css``
      }
    }
  }}
`
export { Wrapper, NumberInputSection, InputSection }
