import React, { Component } from 'react'
import Select from '../Select'
import { components } from 'react-select'
import PropTypes from 'prop-types'

const CustomOption = ({ children, ...props }) => {
  const { currentSelectedText } = props
  return (
    <components.Option {...props}>
      {children}
      <div
        style={{
          fontFamily: children,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '184px',
          overflow: 'hidden',
          marginTop: '10px'
        }}
        dangerouslySetInnerHTML={{ __html: currentSelectedText }}
      />
    </components.Option>
  )
}
class FontPicker extends Component {
  handleFocus(e) {
    if (this.props.onFocus && typeof this.props.onFocus === 'function') {
      this.props.onFocus(e)
    }
  }

  handleBlur(e) {
    if (this.props.onBlur && typeof this.props.onBlur === 'function') {
      this.props.onBlur(e)
    }
  }

  render() {
    const {
      fonts = [],
      onBlur,
      setFont,
      index,
      value: selectedFont,
      label,
      placeholder,
      handleSearch,
      className
    } = this.props
    return (
      <Select
        value={fonts.find((font) => font.value === selectedFont)}
        onBlur={onBlur}
        tabIndex={-1}
        onChange={(option) => setFont(option, index)}
        onMenuOpen={(e) => this.handleFocus(e)}
        onMenuClose={(e) => this.handleBlur(e)}
        options={fonts}
        placeholder={placeholder}
        onSearch={handleSearch}
        customProps={{
          components: {
            Option: (props) => (
              <CustomOption {...props} currentSelectedText={label} />
            )
          }
        }}
        className={className}
      />
    )
  }
}

FontPicker.propTypes = {
  /* array of object in the form of {label : string , value: string} */
  fonts: PropTypes.arrayOf(PropTypes.object),
  /* callback function which will return onBlur event */
  onBlur: PropTypes.func,
  /* will return value and index of the selected form */
  setFont: PropTypes.func,
  /* index of the selected value */
  index: PropTypes.number,
  /* determines the value of the selected text */
  value: PropTypes.any,
  /* the current label (can be dom element / string) */
  label: PropTypes.any,
  /* specifies the placeholder of the drop down */
  placeholder: PropTypes.string,
  /* callback function which will return onSearch event */
  handleSearch: PropTypes.func,
  className: PropTypes.string
}
FontPicker.defaultProps = {
  fonts: [],
  onBlur: (e) => e,
  placeholder: 'Select Font',
  className: '',
  label: ''
}

export default FontPicker
