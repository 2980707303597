import React, { useState, useEffect } from 'react'
import { SliderWithInputWrapper } from './styles'
import Slider, { SliderTooltip } from 'rc-slider'
import PropTypes from 'prop-types'
import 'rc-slider/assets/index.css'
import NumberInput from '../NumberInput/NumberInput'

const { Handle } = Slider

const SliderWithInput = ({ min, max, step, val, handleChange, label }) => {
  const [sliderValue, setSliderValue] = useState(val)
  useEffect(() => {
    setSliderValue(val)
  }, [val])
  const handleSlider = (props) => {
    const { value, marks, dragging, index, ...restProps } = props
    return (
      <SliderTooltip
        prefixCls='rc-slider-tooltip'
        overlay={`${value} %`}
        visible={dragging}
        placement='top'
        key={index}
      >
        <Handle value={sliderValue} {...restProps} />
      </SliderTooltip>
    )
  }

  return (
    <SliderWithInputWrapper>
      {label && <label className='make-ui-slider-label'>{label}</label>}
      <div className='flex-row'>
        <div className='make-ui-slider'>
          <Slider
            min={min}
            max={max}
            // defaultValue={0}
            handle={handleSlider}
            onChange={(value) => {
              handleChange(value)
              setSliderValue(value)
            }}
            value={sliderValue}
          />
        </div>
        <div className='make-ui-slider-input'>
          <NumberInput
            type='number'
            value={sliderValue}
            min={min}
            max={max}
            step={step}
            onChange={(e) => {
              const inputValue = e.target.value
              handleChange(+inputValue)
              setSliderValue(+inputValue)
            }}
            className='makeui-no-margin'
          />
        </div>
      </div>
    </SliderWithInputWrapper>
  )
}

SliderWithInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  val: PropTypes.any,
  handleChange: PropTypes.func,
  allowDecimal: PropTypes.bool,
  label: PropTypes.string
}
SliderWithInput.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  val: 0,
  className: '',
  allowDecimal: true
}

export default SliderWithInput
