import styled, { css } from 'styled-components'
import { hexColor, radio } from '../../utils/styles/theme'

export const defaultStyle = css`
  input[type='radio'] {
    display: none;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  label {
    padding-left: 25px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
    display: block;

    :before {
      position: absolute;
      width: 16px;
      height: 16px;
      border: 1px solid ${radio.BORDER};
      left: 0;
      top: 3px;
      content: '';
      border-radius: 50%;
      transition: 0.3s ease;
    }
    :after {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: ${radio.HOVER};
      left: 5px;
      top: 8px;
      content: '';
      border-radius: 50%;
      transition: 0.3s ease;
      transform: scale(0);
    }

    &:hover {
      &:before {
        border: 1px solid ${radio.HOVER};
      }
    }
  }

  input[type='radio']:checked + label {
    &:before {
      border: 1px solid ${radio.HOVER};
    }
    &:after {
      transform: scale(1);
    }
  }
`

export const buttonStyle = css`
  display: flex;
  input {
    display: none;
  }

  label {
    padding: 10px;
    margin: 0;
    cursor: pointer;
    line-height: 110%;
    transition: 0.3s ease;
    font-size: 14px;

    svg {
      transition: 0.3s ease;
      fill: #4a4a4a;
    }

    &:hover {
      background-color: ${hexColor.lightBlue};
      color: ${hexColor.blue};

      svg {
        fill: ${hexColor.blue};
      }
    }
  }

  input:checked + label {
    background-color: ${hexColor.lightBlue};
    color: ${hexColor.blue};
    svg {
      fill: ${hexColor.blue};
    }
  }
`

export const buttonStyleOutline = css`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 2px;
`

export const StyledRadioGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
  label {
    font-size: 13px;
    line-height: 22px;
    margin: 0 0 5px 0;
    color: rgb(79, 79, 79);
  }
  > div {
    display: flex;
    flex-direction: ${({ alignment }) =>
      alignment === 'vertical' ? 'coloumn' : 'row'};
    gap: ${({ variant }) => (variant === 'button' ? '0' : '10px')};
    ${({ variant }) => variant === 'button' && buttonStyleOutline}
    > div {
      ${({ variant }) => (variant === 'button' ? buttonStyle : defaultStyle)}
    }
  }
`
export const DefaultRadio = styled.div`
  ${({ variant }) => (variant === 'button' ? '' : defaultStyle)}
`
