export const fontAwesome = {
  Accessibility: [
    'fab fa-accessible-icon',
    'fas fa-american-sign-language-interpreting',
    'fas fa-assistive-listening-systems',
    'fas fa-audio-description',
    'fas fa-blind',
    'fas fa-braille',
    'fas fa-closed-captioning',
    'far fa-closed-captioning',
    'fas fa-deaf',
    'fas fa-low-vision',
    'fas fa-phone-volume',
    'fas fa-question-circle',
    'far fa-question-circle',
    'fas fa-sign-language',
    'fas fa-tty',
    'fas fa-universal-access',
    'fas fa-wheelchair'
  ],
  Arrows: [
    'fas fa-angle-double-down',
    'fas fa-angle-double-left',
    'fas fa-angle-double-right',
    'fas fa-angle-double-up',
    'fas fa-angle-down',
    'fas fa-angle-left',
    'fas fa-angle-right',
    'fas fa-angle-up',
    'fas fa-arrow-alt-circle-down',
    'far fa-arrow-alt-circle-down',
    'fas fa-arrow-alt-circle-left',
    'far fa-arrow-alt-circle-left',
    'fas fa-arrow-alt-circle-right',
    'far fa-arrow-alt-circle-right',
    'fas fa-arrow-alt-circle-up',
    'far fa-arrow-alt-circle-up',
    'fas fa-arrow-circle-down',
    'fas fa-arrow-circle-left',
    'fas fa-arrow-circle-right',
    'fas fa-arrow-circle-up',
    'fas fa-arrow-down',
    'fas fa-arrow-left',
    'fas fa-arrow-right',
    'fas fa-arrow-up',
    'fas fa-arrows-alt',
    'fas fa-arrows-alt-h',
    'fas fa-arrows-alt-v',
    'fas fa-caret-down',
    'fas fa-caret-left',
    'fas fa-caret-right',
    'fas fa-caret-square-down',
    'far fa-caret-square-down',
    'fas fa-caret-square-left',
    'far fa-caret-square-left',
    'fas fa-caret-square-right',
    'far fa-caret-square-right',
    'fas fa-caret-square-up',
    'far fa-caret-square-up',
    'fas fa-caret-up',
    'fas fa-cart-arrow-down',
    'fas fa-chart-line',
    'fas fa-chevron-circle-down',
    'fas fa-chevron-circle-left',
    'fas fa-chevron-circle-right',
    'fas fa-chevron-circle-up',
    'fas fa-chevron-down',
    'fas fa-chevron-left',
    'fas fa-chevron-right',
    'fas fa-chevron-up',
    'fas fa-cloud-download-alt',
    'fas fa-cloud-upload-alt',
    'fas fa-download',
    'fas fa-exchange-alt',
    'fas fa-expand-arrows-alt',
    'fas fa-external-link-alt',
    'fas fa-external-link-square-alt',
    'fas fa-hand-point-down',
    'far fa-hand-point-down',
    'fas fa-hand-point-left',
    'far fa-hand-point-left',
    'fas fa-hand-point-right',
    'far fa-hand-point-right',
    'fas fa-hand-point-up',
    'far fa-hand-point-up',
    'fas fa-hand-pointer',
    'far fa-hand-pointer',
    'fas fa-history',
    'fas fa-level-down-alt',
    'fas fa-level-up-alt',
    'fas fa-location-arrow',
    'fas fa-long-arrow-alt-down',
    'fas fa-long-arrow-alt-left',
    'fas fa-long-arrow-alt-right',
    'fas fa-long-arrow-alt-up',
    'fas fa-mouse-pointer',
    'fas fa-play',
    'fas fa-random',
    'fas fa-recycle',
    'fas fa-redo',
    'fas fa-redo-alt',
    'fas fa-reply',
    'fas fa-reply-all',
    'fas fa-retweet',
    'fas fa-share',
    'fas fa-share-square',
    'far fa-share-square',
    'fas fa-sign-in-alt',
    'fas fa-sign-out-alt',
    'fas fa-sort',
    'fas fa-sort-alpha-down',
    'fas fa-sort-alpha-up',
    'fas fa-sort-amount-down',
    'fas fa-sort-amount-up',
    'fas fa-sort-down',
    'fas fa-sort-numeric-down',
    'fas fa-sort-numeric-up',
    'fas fa-sort-up',
    'fas fa-sync',
    'fas fa-sync-alt',
    'fas fa-text-height',
    'fas fa-undo',
    'fas fa-undo-alt',
    'fas fa-upload'
  ],
  'Audio & Video': [
    'fas fa-audio-description',
    'fas fa-backward',
    'fas fa-circle',
    'far fa-circle',
    'fas fa-closed-captioning',
    'far fa-closed-captioning',
    'fas fa-compress',
    'fas fa-eject',
    'fas fa-expand',
    'fas fa-expand-arrows-alt',
    'fas fa-fast-backward',
    'fas fa-fast-forward',
    'fas fa-file-audio',
    'far fa-file-audio',
    'fas fa-file-video',
    'far fa-file-video',
    'fas fa-film',
    'fas fa-forward',
    'fas fa-headphones',
    'fas fa-microphone',
    'fas fa-microphone-slash',
    'fas fa-music',
    'fas fa-pause',
    'fas fa-pause-circle',
    'far fa-pause-circle',
    'fas fa-phone-volume',
    'fas fa-play',
    'fas fa-play-circle',
    'far fa-play-circle',
    'fas fa-podcast',
    'fas fa-random',
    'fas fa-redo',
    'fas fa-redo-alt',
    'fas fa-rss',
    'fas fa-rss-square',
    'fas fa-step-backward',
    'fas fa-step-forward',
    'fas fa-stop',
    'fas fa-stop-circle',
    'far fa-stop-circle',
    'fas fa-sync',
    'fas fa-sync-alt',
    'fas fa-undo',
    'fas fa-undo-alt',
    'fas fa-video',
    'fas fa-volume-down',
    'fas fa-volume-off',
    'fas fa-volume-up'
  ],
  Business: [
    'fas fa-address-book',
    'far fa-address-book',
    'fas fa-address-card',
    'far fa-address-card',
    'fas fa-archive',
    'fas fa-balance-scale',
    'fas fa-birthday-cake',
    'fas fa-book',
    'fas fa-briefcase',
    'fas fa-building',
    'far fa-building',
    'fas fa-bullhorn',
    'fas fa-bullseye',
    'fas fa-calculator',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-certificate',
    'fas fa-chart-area',
    'fas fa-chart-bar',
    'far fa-chart-bar',
    'fas fa-chart-line',
    'fas fa-chart-pie',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-coffee',
    'fas fa-columns',
    'fas fa-compass',
    'far fa-compass',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-copyright',
    'far fa-copyright',
    'fas fa-cut',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-envelope-square',
    'fas fa-eraser',
    'fas fa-fax',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-globe',
    'fas fa-industry',
    'fas fa-paperclip',
    'fas fa-paste',
    'fas fa-pen-square',
    'fas fa-pencil-alt',
    'fas fa-percent',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-phone-volume',
    'fas fa-registered',
    'far fa-registered',
    'fas fa-save',
    'far fa-save',
    'fas fa-sitemap',
    'fas fa-sticky-note',
    'far fa-sticky-note',
    'fas fa-suitcase',
    'fas fa-table',
    'fas fa-tag',
    'fas fa-tags',
    'fas fa-tasks',
    'fas fa-thumbtack',
    'fas fa-trademark'
  ],
  Chess: [
    'fas fa-chess',
    'fas fa-chess-bishop',
    'fas fa-chess-board',
    'fas fa-chess-king',
    'fas fa-chess-knight',
    'fas fa-chess-pawn',
    'fas fa-chess-queen',
    'fas fa-chess-rook',
    'fas fa-square-full'
  ],
  Code: [
    'fas fa-archive',
    'fas fa-barcode',
    'fas fa-bath',
    'fas fa-bug',
    'fas fa-code',
    'fas fa-code-branch',
    'fas fa-coffee',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-file-code',
    'far fa-file-code',
    'fas fa-filter',
    'fas fa-fire-extinguisher',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-microchip',
    'fas fa-qrcode',
    'fas fa-shield-alt',
    'fas fa-sitemap',
    'fas fa-terminal',
    'fas fa-user-secret',
    'fas fa-window-close',
    'far fa-window-close',
    'fas fa-window-maximize',
    'far fa-window-maximize',
    'fas fa-window-minimize',
    'far fa-window-minimize',
    'fas fa-window-restore',
    'far fa-window-restore'
  ],
  Communication: [
    'fas fa-address-book',
    'far fa-address-book',
    'fas fa-address-card',
    'far fa-address-card',
    'fas fa-american-sign-language-interpreting',
    'fas fa-assistive-listening-systems',
    'fas fa-at',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fab fa-bluetooth',
    'fab fa-bluetooth-b',
    'fas fa-bullhorn',
    'fas fa-comment',
    'far fa-comment',
    'fas fa-comment-alt',
    'far fa-comment-alt',
    'fas fa-comments',
    'far fa-comments',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-envelope-square',
    'fas fa-fax',
    'fas fa-inbox',
    'fas fa-language',
    'fas fa-microphone',
    'fas fa-microphone-slash',
    'fas fa-mobile',
    'fas fa-mobile-alt',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-phone-volume',
    'fas fa-rss',
    'fas fa-rss-square',
    'fas fa-tty',
    'fas fa-wifi'
  ],
  Computers: [
    'fas fa-desktop',
    'fas fa-download',
    'fas fa-hdd',
    'far fa-hdd',
    'fas fa-headphones',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-laptop',
    'fas fa-microchip',
    'fas fa-mobile',
    'fas fa-mobile-alt',
    'fas fa-plug',
    'fas fa-power-off',
    'fas fa-print',
    'fas fa-save',
    'far fa-save',
    'fas fa-server',
    'fas fa-tablet',
    'fas fa-tablet-alt',
    'fas fa-tv',
    'fas fa-upload'
  ],
  Currency: [
    'fab fa-bitcoin',
    'fab fa-btc',
    'fas fa-dollar-sign',
    'fas fa-euro-sign',
    'fab fa-gg',
    'fab fa-gg-circle',
    'fas fa-lira-sign',
    'fas fa-money-bill-alt',
    'far fa-money-bill-alt',
    'fas fa-pound-sign',
    'fas fa-ruble-sign',
    'fas fa-rupee-sign',
    'fas fa-shekel-sign',
    'fas fa-won-sign',
    'fas fa-yen-sign'
  ],
  'Date & Time': [
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-calendar-check',
    'far fa-calendar-check',
    'fas fa-calendar-minus',
    'far fa-calendar-minus',
    'fas fa-calendar-plus',
    'far fa-calendar-plus',
    'fas fa-calendar-times',
    'far fa-calendar-times',
    'fas fa-clock',
    'far fa-clock',
    'fas fa-hourglass',
    'far fa-hourglass',
    'fas fa-hourglass-end',
    'fas fa-hourglass-half',
    'fas fa-hourglass-start',
    'fas fa-stopwatch'
  ],
  Design: [
    'fas fa-adjust',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-crop',
    'fas fa-crosshairs',
    'fas fa-cut',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-eraser',
    'fas fa-eye',
    'fas fa-eye-dropper',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-object-group',
    'far fa-object-group',
    'fas fa-object-ungroup',
    'far fa-object-ungroup',
    'fas fa-paint-brush',
    'fas fa-paste',
    'fas fa-pencil-alt',
    'fas fa-save',
    'far fa-save',
    'fas fa-tint'
  ],
  Editors: [
    'fas fa-align-center',
    'fas fa-align-justify',
    'fas fa-align-left',
    'fas fa-align-right',
    'fas fa-bold',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-columns',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cut',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-eraser',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-font',
    'fas fa-heading',
    'fas fa-i-cursor',
    'fas fa-indent',
    'fas fa-italic',
    'fas fa-link',
    'fas fa-list',
    'fas fa-list-alt',
    'far fa-list-alt',
    'fas fa-list-ol',
    'fas fa-list-ul',
    'fas fa-outdent',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-paperclip',
    'fas fa-paragraph',
    'fas fa-paste',
    'fas fa-pencil-alt',
    'fas fa-print',
    'fas fa-quote-left',
    'fas fa-quote-right',
    'fas fa-redo',
    'fas fa-redo-alt',
    'fas fa-reply',
    'fas fa-reply-all',
    'fas fa-share',
    'fas fa-strikethrough',
    'fas fa-subscript',
    'fas fa-superscript',
    'fas fa-sync',
    'fas fa-sync-alt',
    'fas fa-table',
    'fas fa-tasks',
    'fas fa-text-height',
    'fas fa-text-width',
    'fas fa-th',
    'fas fa-th-large',
    'fas fa-th-list',
    'fas fa-trash',
    'fas fa-trash-alt',
    'far fa-trash-alt',
    'fas fa-underline',
    'fas fa-undo',
    'fas fa-undo-alt',
    'fas fa-unlink'
  ],
  Files: [
    'fas fa-archive',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cut',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-file-archive',
    'far fa-file-archive',
    'fas fa-file-audio',
    'far fa-file-audio',
    'fas fa-file-code',
    'far fa-file-code',
    'fas fa-file-excel',
    'far fa-file-excel',
    'fas fa-file-image',
    'far fa-file-image',
    'fas fa-file-pdf',
    'far fa-file-pdf',
    'fas fa-file-powerpoint',
    'far fa-file-powerpoint',
    'fas fa-file-video',
    'far fa-file-video',
    'fas fa-file-word',
    'far fa-file-word',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-paste',
    'fas fa-save',
    'far fa-save',
    'fas fa-sticky-note',
    'far fa-sticky-note'
  ],
  Genders: [
    'fas fa-genderless',
    'fas fa-mars',
    'fas fa-mars-double',
    'fas fa-mars-stroke',
    'fas fa-mars-stroke-h',
    'fas fa-mars-stroke-v',
    'fas fa-mercury',
    'fas fa-neuter',
    'fas fa-transgender',
    'fas fa-transgender-alt',
    'fas fa-venus',
    'fas fa-venus-double',
    'fas fa-venus-mars'
  ],
  Hands: [
    'fas fa-hand-lizard',
    'far fa-hand-lizard',
    'fas fa-hand-paper',
    'far fa-hand-paper',
    'fas fa-hand-peace',
    'far fa-hand-peace',
    'fas fa-hand-point-down',
    'far fa-hand-point-down',
    'fas fa-hand-point-left',
    'far fa-hand-point-left',
    'fas fa-hand-point-right',
    'far fa-hand-point-right',
    'fas fa-hand-point-up',
    'far fa-hand-point-up',
    'fas fa-hand-pointer',
    'far fa-hand-pointer',
    'fas fa-hand-rock',
    'far fa-hand-rock',
    'fas fa-hand-scissors',
    'far fa-hand-scissors',
    'fas fa-hand-spock',
    'far fa-hand-spock',
    'fas fa-handshake',
    'far fa-handshake',
    'fas fa-thumbs-down',
    'far fa-thumbs-down',
    'fas fa-thumbs-up',
    'far fa-thumbs-up'
  ],
  Health: [
    'fab fa-accessible-icon',
    'fas fa-ambulance',
    'fas fa-h-square',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-heartbeat',
    'fas fa-hospital',
    'far fa-hospital',
    'fas fa-medkit',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-stethoscope',
    'fas fa-user-md'
  ],
  Images: [
    'fas fa-adjust',
    'fas fa-bolt',
    'fas fa-camera',
    'fas fa-camera-retro',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-compress',
    'fas fa-expand',
    'fas fa-eye',
    'fas fa-eye-dropper',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-file-image',
    'far fa-file-image',
    'fas fa-film',
    'fas fa-id-badge',
    'far fa-id-badge',
    'fas fa-id-card',
    'far fa-id-card',
    'fas fa-image',
    'far fa-image',
    'fas fa-images',
    'far fa-images',
    'fas fa-sliders-h',
    'fas fa-tint'
  ],
  Interfaces: [
    'fas fa-ban',
    'fas fa-barcode',
    'fas fa-bars',
    'fas fa-beer',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-bug',
    'fas fa-bullhorn',
    'fas fa-bullseye',
    'fas fa-calculator',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-calendar-check',
    'far fa-calendar-check',
    'fas fa-calendar-minus',
    'far fa-calendar-minus',
    'fas fa-calendar-plus',
    'far fa-calendar-plus',
    'fas fa-calendar-times',
    'far fa-calendar-times',
    'fas fa-certificate',
    'fas fa-check',
    'fas fa-check-circle',
    'far fa-check-circle',
    'fas fa-check-square',
    'far fa-check-square',
    'fas fa-circle',
    'far fa-circle',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-clone',
    'far fa-clone',
    'fas fa-cloud',
    'fas fa-cloud-download-alt',
    'fas fa-cloud-upload-alt',
    'fas fa-coffee',
    'fas fa-cog',
    'fas fa-cogs',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cut',
    'fas fa-database',
    'fas fa-dot-circle',
    'far fa-dot-circle',
    'fas fa-download',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-ellipsis-h',
    'fas fa-ellipsis-v',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-eraser',
    'fas fa-exclamation',
    'fas fa-exclamation-circle',
    'fas fa-exclamation-triangle',
    'fas fa-external-link-alt',
    'fas fa-external-link-square-alt',
    'fas fa-eye',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-filter',
    'fas fa-flag',
    'far fa-flag',
    'fas fa-flag-checkered',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-frown',
    'far fa-frown',
    'fas fa-hashtag',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-history',
    'fas fa-home',
    'fas fa-i-cursor',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-language',
    'fas fa-magic',
    'fas fa-meh',
    'far fa-meh',
    'fas fa-microphone',
    'fas fa-microphone-slash',
    'fas fa-minus',
    'fas fa-minus-circle',
    'fas fa-minus-square',
    'far fa-minus-square',
    'fas fa-paste',
    'fas fa-pencil-alt',
    'fas fa-plus',
    'fas fa-plus-circle',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-qrcode',
    'fas fa-question',
    'fas fa-question-circle',
    'far fa-question-circle'
  ],
  Maps: [
    'fas fa-ambulance',
    'fas fa-anchor',
    'fas fa-balance-scale',
    'fas fa-bath',
    'fas fa-bed',
    'fas fa-beer',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-bicycle',
    'fas fa-binoculars',
    'fas fa-birthday-cake',
    'fas fa-blind',
    'fas fa-bomb',
    'fas fa-book',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-briefcase',
    'fas fa-building',
    'far fa-building',
    'fas fa-car',
    'fas fa-coffee',
    'fas fa-crosshairs',
    'fas fa-dollar-sign',
    'fas fa-eye',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-fighter-jet',
    'fas fa-fire',
    'fas fa-fire-extinguisher',
    'fas fa-flag',
    'far fa-flag',
    'fas fa-flag-checkered',
    'fas fa-flask',
    'fas fa-gamepad',
    'fas fa-gavel',
    'fas fa-gift',
    'fas fa-glass-martini',
    'fas fa-globe',
    'fas fa-graduation-cap',
    'fas fa-h-square',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-heartbeat',
    'fas fa-home',
    'fas fa-hospital',
    'far fa-hospital',
    'fas fa-image',
    'far fa-image',
    'fas fa-images',
    'far fa-images',
    'fas fa-industry',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-key',
    'fas fa-leaf',
    'fas fa-lemon',
    'far fa-lemon',
    'fas fa-life-ring',
    'far fa-life-ring',
    'fas fa-lightbulb',
    'far fa-lightbulb',
    'fas fa-location-arrow',
    'fas fa-low-vision',
    'fas fa-magnet',
    'fas fa-male',
    'fas fa-map',
    'far fa-map',
    'fas fa-map-marker',
    'fas fa-map-marker-alt',
    'fas fa-map-pin',
    'fas fa-map-signs',
    'fas fa-medkit',
    'fas fa-money-bill-alt',
    'far fa-money-bill-alt',
    'fas fa-motorcycle',
    'fas fa-music',
    'fas fa-newspaper',
    'far fa-newspaper',
    'fas fa-paw',
    'fas fa-phone',
    'fas fa-phone-square',
    'fas fa-phone-volume',
    'fas fa-plane',
    'fas fa-plug',
    'fas fa-plus',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-print',
    'fas fa-recycle',
    'fas fa-road',
    'fas fa-rocket',
    'fas fa-search',
    'fas fa-search-minus',
    'fas fa-search-plus',
    'fas fa-ship',
    'fas fa-shopping-bag',
    'fas fa-shopping-basket',
    'fas fa-shopping-cart',
    'fas fa-shower',
    'fas fa-street-view',
    'fas fa-subway',
    'fas fa-suitcase',
    'fas fa-tag',
    'fas fa-tags',
    'fas fa-taxi',
    'fas fa-thumbtack'
  ],
  Objects: [
    'fas fa-ambulance',
    'fas fa-anchor',
    'fas fa-archive',
    'fas fa-balance-scale',
    'fas fa-bath',
    'fas fa-bed',
    'fas fa-beer',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bicycle',
    'fas fa-binoculars',
    'fas fa-birthday-cake',
    'fas fa-bomb',
    'fas fa-book',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-briefcase',
    'fas fa-bug',
    'fas fa-building',
    'far fa-building',
    'fas fa-bullhorn',
    'fas fa-bullseye',
    'fas fa-bus',
    'fas fa-calculator',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-camera',
    'fas fa-camera-retro',
    'fas fa-car',
    'fas fa-clipboard',
    'far fa-clipboard',
    'fas fa-cloud',
    'fas fa-coffee',
    'fas fa-cog',
    'fas fa-cogs',
    'fas fa-compass',
    'far fa-compass',
    'fas fa-copy',
    'far fa-copy',
    'fas fa-cube',
    'fas fa-cubes',
    'fas fa-cut',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-eraser',
    'fas fa-eye',
    'fas fa-eye-dropper',
    'fas fa-fax',
    'fas fa-fighter-jet',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-film',
    'fas fa-fire',
    'fas fa-fire-extinguisher',
    'fas fa-flag',
    'far fa-flag',
    'fas fa-flag-checkered',
    'fas fa-flask',
    'fas fa-futbol',
    'far fa-futbol',
    'fas fa-gamepad',
    'fas fa-gavel',
    'fas fa-gem',
    'far fa-gem',
    'fas fa-gift',
    'fas fa-glass-martini',
    'fas fa-globe',
    'fas fa-graduation-cap',
    'fas fa-hdd',
    'far fa-hdd',
    'fas fa-headphones',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-home',
    'fas fa-hospital',
    'far fa-hospital',
    'fas fa-hourglass',
    'far fa-hourglass',
    'fas fa-image',
    'far fa-image',
    'fas fa-images',
    'far fa-images',
    'fas fa-industry',
    'fas fa-key',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-laptop',
    'fas fa-leaf',
    'fas fa-lemon',
    'far fa-lemon',
    'fas fa-life-ring',
    'far fa-life-ring',
    'fas fa-lightbulb',
    'far fa-lightbulb',
    'fas fa-lock',
    'fas fa-lock-open',
    'fas fa-magic',
    'fas fa-magnet',
    'fas fa-map',
    'far fa-map',
    'fas fa-map-marker',
    'fas fa-map-marker-alt'
  ],
  'Payments & Shopping': [
    'fab fa-amazon-pay',
    'fab fa-apple-pay',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-bullhorn',
    'fas fa-camera',
    'fas fa-camera-retro',
    'fas fa-cart-arrow-down',
    'fas fa-cart-plus',
    'fab fa-cc-amazon-pay',
    'fab fa-cc-amex',
    'fab fa-cc-apple-pay',
    'fab fa-cc-diners-club',
    'fab fa-cc-discover',
    'fab fa-cc-jcb',
    'fab fa-cc-mastercard',
    'fab fa-cc-paypal',
    'fab fa-cc-stripe',
    'fab fa-cc-visa',
    'fas fa-certificate',
    'fas fa-credit-card',
    'far fa-credit-card',
    'fab fa-ethereum',
    'fas fa-gem',
    'far fa-gem',
    'fas fa-gift',
    'fab fa-google-wallet',
    'fas fa-handshake',
    'far fa-handshake',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-key',
    'fab fa-paypal',
    'fas fa-shopping-bag',
    'fas fa-shopping-basket',
    'fas fa-shopping-cart',
    'fas fa-star',
    'far fa-star',
    'fab fa-stripe',
    'fab fa-stripe-s',
    'fas fa-tag',
    'fas fa-tags',
    'fas fa-thumbs-down',
    'far fa-thumbs-down',
    'fas fa-thumbs-up',
    'far fa-thumbs-up',
    'fas fa-trophy'
  ],
  Shapes: [
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-certificate',
    'fas fa-circle',
    'far fa-circle',
    'fas fa-cloud',
    'fas fa-comment',
    'far fa-comment',
    'fas fa-file',
    'far fa-file',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-heart',
    'far fa-heart',
    'fas fa-map-marker',
    'fas fa-play',
    'fas fa-square',
    'far fa-square',
    'fas fa-star',
    'far fa-star'
  ],
  Spinners: [
    'fas fa-asterisk',
    'fas fa-certificate',
    'fas fa-circle-notch',
    'fas fa-cog',
    'fas fa-compass',
    'far fa-compass',
    'fas fa-crosshairs',
    'fas fa-life-ring',
    'far fa-life-ring',
    'fas fa-snowflake',
    'far fa-snowflake',
    'fas fa-spinner',
    'fas fa-sun',
    'far fa-sun',
    'fas fa-sync'
  ],
  Sports: [
    'fas fa-baseball-ball',
    'fas fa-basketball-ball',
    'fas fa-bowling-ball',
    'fas fa-football-ball',
    'fas fa-futbol',
    'far fa-futbol',
    'fas fa-golf-ball',
    'fas fa-hockey-puck',
    'fas fa-quidditch',
    'fas fa-table-tennis',
    'fas fa-volleyball-ball'
  ],
  Status: [
    'fas fa-ban',
    'fas fa-battery-empty',
    'fas fa-battery-full',
    'fas fa-battery-half',
    'fas fa-battery-quarter',
    'fas fa-battery-three-quarters',
    'fas fa-bell',
    'far fa-bell',
    'fas fa-bell-slash',
    'far fa-bell-slash',
    'fas fa-calendar',
    'far fa-calendar',
    'fas fa-calendar-alt',
    'far fa-calendar-alt',
    'fas fa-calendar-check',
    'far fa-calendar-check',
    'fas fa-calendar-minus',
    'far fa-calendar-minus',
    'fas fa-calendar-plus',
    'far fa-calendar-plus',
    'fas fa-calendar-times',
    'far fa-calendar-times',
    'fas fa-cart-arrow-down',
    'fas fa-cart-plus',
    'fas fa-exclamation',
    'fas fa-exclamation-circle',
    'fas fa-exclamation-triangle',
    'fas fa-eye',
    'fas fa-eye-slash',
    'far fa-eye-slash',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-info',
    'fas fa-info-circle',
    'fas fa-lock',
    'fas fa-lock-open',
    'fas fa-minus',
    'fas fa-minus-circle',
    'fas fa-minus-square',
    'far fa-minus-square',
    'fas fa-plus',
    'fas fa-plus-circle',
    'fas fa-plus-square',
    'far fa-plus-square',
    'fas fa-question',
    'fas fa-question-circle',
    'far fa-question-circle',
    'fas fa-shield-alt',
    'fas fa-shopping-cart',
    'fas fa-sign-in-alt',
    'fas fa-sign-out-alt',
    'fas fa-thermometer-empty',
    'fas fa-thermometer-full',
    'fas fa-thermometer-half',
    'fas fa-thermometer-quarter',
    'fas fa-thermometer-three-quarters',
    'fas fa-thumbs-down',
    'far fa-thumbs-down',
    'fas fa-thumbs-up',
    'far fa-thumbs-up',
    'fas fa-toggle-off',
    'fas fa-toggle-on',
    'fas fa-unlock',
    'fas fa-unlock-alt'
  ],
  'Users & People': [
    'fab fa-accessible-icon',
    'fas fa-address-book',
    'far fa-address-book',
    'fas fa-address-card',
    'far fa-address-card',
    'fas fa-bed',
    'fas fa-blind',
    'fas fa-child',
    'fas fa-female',
    'fas fa-frown',
    'far fa-frown',
    'fas fa-id-badge',
    'far fa-id-badge',
    'fas fa-id-card',
    'far fa-id-card',
    'fas fa-male',
    'fas fa-meh',
    'far fa-meh',
    'fas fa-power-off',
    'fas fa-smile',
    'far fa-smile',
    'fas fa-street-view',
    'fas fa-user',
    'far fa-user',
    'fas fa-user-circle',
    'far fa-user-circle',
    'fas fa-user-md',
    'fas fa-user-plus',
    'fas fa-user-secret',
    'fas fa-user-times',
    'fas fa-users'
  ],
  Vehicles: [
    'fab fa-accessible-icon',
    'fas fa-ambulance',
    'fas fa-bicycle',
    'fas fa-bus',
    'fas fa-car',
    'fas fa-fighter-jet',
    'fas fa-motorcycle',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-plane',
    'fas fa-rocket',
    'fas fa-ship',
    'fas fa-shopping-cart',
    'fas fa-space-shuttle',
    'fas fa-subway',
    'fas fa-taxi',
    'fas fa-train',
    'fas fa-truck'
  ],
  Writing: [
    'fas fa-archive',
    'fas fa-book',
    'fas fa-bookmark',
    'far fa-bookmark',
    'fas fa-edit',
    'far fa-edit',
    'fas fa-envelope',
    'far fa-envelope',
    'fas fa-envelope-open',
    'far fa-envelope-open',
    'fas fa-eraser',
    'fas fa-file',
    'far fa-file',
    'fas fa-file-alt',
    'far fa-file-alt',
    'fas fa-folder',
    'far fa-folder',
    'fas fa-folder-open',
    'far fa-folder-open',
    'fas fa-keyboard',
    'far fa-keyboard',
    'fas fa-newspaper',
    'far fa-newspaper',
    'fas fa-paper-plane',
    'far fa-paper-plane',
    'fas fa-paperclip',
    'fas fa-paragraph',
    'fas fa-pen-square',
    'fas fa-pencil-alt',
    'fas fa-quote-left',
    'fas fa-quote-right',
    'fas fa-sticky-note',
    'far fa-sticky-note',
    'fas fa-thumbtack'
  ],
  Chat: ['fas fa-comment-alt'],
  Social: [
    'fab fa-facebook',
    'fab fa-whatsapp',
    'fab fa-youtube',
    'fab fa-twitter',
    'fab fa-cc-visa'
  ]
}

export const materialIcons = [
  'instagram',
  'twitter',
  'facebook',
  'whatsapp',
  '3d_rotation',
  'ac_unit',
  'access_alarm',
  'access_alarms',
  'access_time',
  'accessibility',
  'accessible',
  'account_balance',
  'account_balance_wallet',
  'account_box',
  'account_circle',
  'adb',
  'add',
  'add_a_photo',
  'add_alarm',
  'add_alert',
  'add_box',
  'add_circle',
  'add_circle_outline',
  'add_location',
  'add_shopping_cart',
  'add_to_photos',
  'add_to_queue',
  'adjust',
  'airline_seat_flat',
  'airline_seat_flat_angled',
  'airline_seat_individual_suite',
  'airline_seat_legroom_extra',
  'airline_seat_legroom_normal',
  'airline_seat_legroom_reduced',
  'airline_seat_recline_extra',
  'airline_seat_recline_normal',
  'airplanemode_active',
  'airplanemode_inactive',
  'airplay',
  'airport_shuttle',
  'alarm',
  'alarm_add',
  'alarm_off',
  'alarm_on',
  'album',
  'all_inclusive',
  'all_out',
  'android',
  'announcement',
  'apps',
  'archive',
  'arrow_back',
  'arrow_downward',
  'arrow_drop_down',
  'arrow_drop_down_circle',
  'arrow_drop_up',
  'arrow_forward',
  'arrow_upward',
  'art_track',
  'aspect_ratio',
  'assessment',
  'assignment',
  'assignment_ind',
  'assignment_late',
  'assignment_return',
  'assignment_returned',
  'assignment_turned_in',
  'assistant',
  'assistant_photo',
  'attach_file',
  'attach_money',
  'attachment',
  'audiotrack',
  'autorenew',
  'av_timer',
  'backspace',
  'backup',
  'battery_alert',
  'battery_charging_full',
  'battery_full',
  'battery_std',
  'battery_unknown',
  'beach_access',
  'beenhere',
  'block',
  'bluetooth',
  'bluetooth_audio',
  'bluetooth_connected',
  'bluetooth_disabled',
  'bluetooth_searching',
  'blur_circular',
  'blur_linear',
  'blur_off',
  'blur_on',
  'book',
  'bookmark',
  'bookmark_border',
  'border_all',
  'border_bottom',
  'border_clear',
  'border_color',
  'border_horizontal',
  'border_inner',
  'border_left',
  'border_outer',
  'border_right',
  'border_style',
  'border_top',
  'border_vertical',
  'branding_watermark',
  'brightness_1',
  'brightness_2',
  'brightness_3',
  'brightness_4',
  'brightness_5',
  'brightness_6',
  'brightness_7',
  'brightness_auto',
  'brightness_high',
  'brightness_low',
  'brightness_medium',
  'broken_image',
  'brush',
  'bubble_chart',
  'bug_report',
  'build',
  'burst_mode',
  'business',
  'business_center',
  'cached',
  'cake',
  'call',
  'call_end',
  'call_made',
  'call_merge',
  'call_missed',
  'call_missed_outgoing',
  'call_received',
  'call_split',
  'call_to_action',
  'camera',
  'camera_alt',
  'camera_enhance',
  'camera_front',
  'camera_rear',
  'camera_roll',
  'cancel',
  'card_giftcard',
  'card_membership',
  'card_travel',
  'casino',
  'cast',
  'cast_connected',
  'center_focus_strong',
  'center_focus_weak',
  'change_history',
  'chat',
  'chat_bubble',
  'chat_bubble_outline',
  'check',
  'check_box',
  'check_box_outline_blank',
  'check_circle',
  'chevron_left',
  'chevron_right',
  'child_care',
  'child_friendly',
  'chrome_reader_mode',
  'class',
  'clear',
  'clear_all',
  'close',
  'closed_caption',
  'cloud',
  'cloud_circle',
  'cloud_done',
  'cloud_download',
  'cloud_off',
  'cloud_queue',
  'cloud_upload',
  'code',
  'collections',
  'collections_bookmark',
  'color_lens',
  'colorize',
  'comment',
  'compare',
  'compare_arrows',
  'computer',
  'confirmation_number',
  'contact_mail',
  'contact_phone',
  'contacts',
  'content_copy',
  'content_cut',
  'content_paste',
  'control_point',
  'control_point_duplicate',
  'copyright',
  'create',
  'create_new_folder',
  'credit_card',
  'crop',
  'crop_16_9',
  'crop_3_2',
  'crop_5_4',
  'crop_7_5',
  'crop_din',
  'crop_free',
  'crop_landscape',
  'crop_original',
  'crop_portrait',
  'crop_rotate',
  'crop_square',
  'dashboard',
  'data_usage',
  'date_range',
  'dehaze',
  'delete',
  'delete_forever',
  'delete_sweep',
  'description',
  'desktop_mac',
  'desktop_windows',
  'details',
  'developer_board',
  'developer_mode',
  'device_hub',
  'devices',
  'devices_other',
  'dialer_sip',
  'dialpad',
  'directions',
  'directions_bike',
  'directions_boat',
  'directions_bus',
  'directions_car',
  'directions_railway',
  'directions_run',
  'directions_subway',
  'directions_transit',
  'directions_walk',
  'disc_full',
  'dns',
  'do_not_disturb',
  'do_not_disturb_alt',
  'do_not_disturb_off',
  'do_not_disturb_on',
  'dock',
  'domain',
  'done',
  'done_all',
  'donut_large',
  'donut_small',
  'drafts',
  'drag_handle',
  'drive_eta',
  'dvr',
  'edit',
  'edit_location',
  'eject',
  'email',
  'enhanced_encryption',
  'equalizer',
  'error',
  'error_outline',
  'euro_symbol',
  'ev_station',
  'event',
  'event_available',
  'event_busy',
  'event_note',
  'event_seat',
  'exit_to_app',
  'expand_less',
  'expand_more',
  'explicit',
  'explore',
  'exposure',
  'exposure_neg_1',
  'exposure_neg_2',
  'exposure_plus_1',
  'exposure_plus_2',
  'exposure_zero',
  'extension',
  'face',
  'fast_forward',
  'fast_rewind',
  'favorite',
  'favorite_border',
  'featured_play_list',
  'featured_video',
  'feedback',
  'fiber_dvr',
  'fiber_manual_record',
  'fiber_new',
  'fiber_pin',
  'fiber_smart_record',
  'file_download',
  'file_upload',
  'filter',
  'filter_1',
  'filter_2',
  'filter_3',
  'filter_4',
  'filter_5',
  'filter_6',
  'filter_7',
  'filter_8',
  'filter_9',
  'filter_9_plus',
  'filter_b_and_w',
  'filter_center_focus',
  'filter_drama',
  'filter_frames',
  'filter_hdr',
  'filter_list',
  'filter_none',
  'filter_tilt_shift',
  'filter_vintage',
  'find_in_page',
  'find_replace',
  'fingerprint',
  'first_page',
  'fitness_center',
  'flag',
  'flare',
  'flash_auto',
  'flash_off',
  'flash_on',
  'flight',
  'flight_land',
  'flight_takeoff',
  'flip',
  'flip_to_back',
  'flip_to_front',
  'folder',
  'folder_open',
  'folder_shared',
  'folder_special',
  'font_download',
  'format_align_center',
  'format_align_justify',
  'format_align_left',
  'format_align_right',
  'format_bold',
  'format_clear',
  'format_color_fill',
  'format_color_reset',
  'format_color_text',
  'format_indent_decrease',
  'format_indent_increase',
  'format_italic',
  'format_line_spacing',
  'format_list_bulleted',
  'format_list_numbered',
  'format_paint',
  'format_quote',
  'format_shapes',
  'format_size',
  'format_strikethrough',
  'format_textdirection_l_to_r',
  'format_textdirection_r_to_l',
  'format_underlined',
  'forum',
  'forward',
  'forward_10',
  'forward_30',
  'forward_5',
  'free_breakfast',
  'fullscreen',
  'fullscreen_exit',
  'functions',
  'g_translate',
  'gamepad',
  'games',
  'gavel',
  'gesture',
  'get_app',
  'gif',
  'golf_course',
  'gps_fixed',
  'gps_not_fixed',
  'gps_off',
  'grade',
  'gradient',
  'grain',
  'graphic_eq',
  'grid_off',
  'grid_on',
  'group',
  'group_add',
  'group_work',
  'hd',
  'hdr_off',
  'hdr_on',
  'hdr_strong',
  'hdr_weak',
  'headset',
  'headset_mic',
  'healing',
  'hearing',
  'help',
  'help_outline',
  'high_quality',
  'highlight',
  'highlight_off',
  'history',
  'home',
  'hot_tub',
  'hotel',
  'hourglass_empty',
  'hourglass_full',
  'http',
  'https',
  'image',
  'image_aspect_ratio',
  'import_contacts',
  'import_export',
  'important_devices',
  'inbox',
  'indeterminate_check_box',
  'info',
  'info_outline',
  'input',
  'insert_chart',
  'insert_comment',
  'insert_drive_file',
  'insert_emoticon',
  'insert_invitation',
  'insert_link',
  'insert_photo',
  'invert_colors',
  'invert_colors_off',
  'iso',
  'keyboard',
  'keyboard_arrow_down',
  'keyboard_arrow_left',
  'keyboard_arrow_right',
  'keyboard_arrow_up',
  'keyboard_backspace',
  'keyboard_capslock',
  'keyboard_hide',
  'keyboard_return',
  'keyboard_tab',
  'keyboard_voice',
  'kitchen',
  'label',
  'label_outline',
  'landscape',
  'language',
  'laptop',
  'laptop_chromebook',
  'laptop_mac',
  'laptop_windows',
  'last_page',
  'launch',
  'layers',
  'layers_clear',
  'leak_add',
  'leak_remove',
  'lens',
  'library_add',
  'library_books',
  'library_music',
  'lightbulb_outline',
  'line_style',
  'line_weight',
  'linear_scale',
  'link',
  'linked_camera',
  'list',
  'live_help',
  'live_tv',
  'local_activity',
  'local_airport',
  'local_atm',
  'local_bar',
  'local_cafe',
  'local_car_wash',
  'local_convenience_store',
  'local_dining',
  'local_drink',
  'local_florist',
  'local_gas_station',
  'local_grocery_store',
  'local_hospital',
  'local_hotel',
  'local_laundry_service',
  'local_library',
  'local_mall',
  'local_movies',
  'local_offer',
  'local_parking',
  'local_pharmacy',
  'local_phone',
  'local_pizza',
  'local_play',
  'local_post_office',
  'local_printshop',
  'local_see',
  'local_shipping',
  'local_taxi',
  'location_city',
  'location_disabled',
  'location_off',
  'location_on',
  'location_searching',
  'lock',
  'lock_open',
  'lock_outline',
  'looks',
  'looks_3',
  'looks_4',
  'looks_5',
  'looks_6',
  'looks_one',
  'looks_two',
  'loop',
  'loupe',
  'low_priority',
  'loyalty',
  'mail',
  'mail_outline',
  'map',
  'markunread',
  'markunread_mailbox',
  'memory',
  'menu',
  'merge_type',
  'message',
  'mic',
  'mic_none',
  'mic_off',
  'mms',
  'mode_comment',
  'mode_edit',
  'monetization_on',
  'money_off',
  'monochrome_photos',
  'mood',
  'mood_bad',
  'more',
  'more_horiz',
  'more_vert',
  'motorcycle',
  'mouse',
  'move_to_inbox',
  'movie',
  'movie_creation',
  'movie_filter',
  'multiline_chart',
  'music_note',
  'music_video',
  'my_location',
  'nature',
  'nature_people',
  'navigate_before',
  'navigate_next',
  'navigation',
  'near_me',
  'network_cell',
  'network_check',
  'network_locked',
  'network_wifi',
  'new_releases',
  'next_week',
  'nfc',
  'no_encryption',
  'no_sim',
  'not_interested',
  'note',
  'note_add',
  'notifications',
  'notifications_active',
  'notifications_none',
  'notifications_off',
  'notifications_paused',
  'offline_pin',
  'ondemand_video',
  'opacity',
  'open_in_browser',
  'open_in_new',
  'open_with',
  'pages',
  'pageview',
  'palette',
  'pan_tool',
  'panorama',
  'panorama_fish_eye',
  'panorama_horizontal',
  'panorama_vertical',
  'panorama_wide_angle',
  'party_mode',
  'pause',
  'pause_circle_filled',
  'pause_circle_outline',
  'payment',
  'people',
  'people_outline',
  'perm_camera_mic',
  'perm_contact_calendar',
  'perm_data_setting',
  'perm_device_information',
  'perm_identity',
  'perm_media',
  'perm_phone_msg',
  'perm_scan_wifi',
  'person',
  'person_add',
  'person_outline',
  'person_pin',
  'person_pin_circle',
  'personal_video',
  'pets',
  'phone',
  'phone_android',
  'phone_bluetooth_speaker',
  'phone_forwarded',
  'phone_in_talk',
  'phone_iphone',
  'phone_locked',
  'phone_missed',
  'phone_paused',
  'phonelink',
  'phonelink_erase',
  'phonelink_lock',
  'phonelink_off',
  'phonelink_ring',
  'phonelink_setup',
  'photo',
  'photo_album',
  'photo_camera',
  'photo_filter',
  'photo_library',
  'photo_size_select_actual',
  'photo_size_select_large',
  'photo_size_select_small',
  'picture_as_pdf',
  'picture_in_picture',
  'picture_in_picture_alt',
  'pie_chart',
  'pie_chart_outlined',
  'pin_drop',
  'place',
  'play_arrow',
  'play_circle_filled',
  'play_circle_outline',
  'play_for_work',
  'playlist_add',
  'playlist_add_check',
  'playlist_play',
  'plus_one',
  'poll',
  'polymer',
  'pool',
  'portable_wifi_off',
  'portrait',
  'power',
  'power_input',
  'power_settings_new',
  'pregnant_woman',
  'present_to_all',
  'print',
  'priority_high',
  'public',
  'publish',
  'query_builder',
  'question_answer',
  'queue',
  'queue_music',
  'queue_play_next',
  'radio',
  'radio_button_checked',
  'radio_button_unchecked',
  'rate_review',
  'receipt',
  'recent_actors',
  'record_voice_over',
  'redeem',
  'redo',
  'refresh',
  'remove',
  'remove_circle',
  'remove_circle_outline',
  'remove_from_queue',
  'remove_red_eye',
  'remove_shopping_cart',
  'reorder',
  'repeat',
  'repeat_one',
  'replay',
  'replay_10',
  'replay_30',
  'replay_5',
  'reply',
  'reply_all',
  'report',
  'report_problem',
  'restaurant',
  'restaurant_menu',
  'restore',
  'restore_page',
  'ring_volume',
  'room',
  'room_service',
  'rotate_90_degrees_ccw',
  'rotate_left',
  'rotate_right',
  'rounded_corner',
  'router',
  'rowing',
  'rss_feed',
  'rv_hookup',
  'satellite',
  'save',
  'scanner',
  'schedule',
  'school',
  'screen_lock_landscape',
  'screen_lock_portrait',
  'screen_lock_rotation',
  'screen_rotation',
  'screen_share',
  'sd_card',
  'sd_storage',
  'search',
  'security',
  'select_all',
  'send',
  'sentiment_dissatisfied',
  'sentiment_neutral',
  'sentiment_satisfied',
  'sentiment_very_dissatisfied',
  'sentiment_very_satisfied',
  'settings',
  'settings_applications',
  'settings_backup_restore',
  'settings_bluetooth',
  'settings_brightness',
  'settings_cell',
  'settings_ethernet',
  'settings_input_antenna',
  'settings_input_component',
  'settings_input_composite',
  'settings_input_hdmi',
  'settings_input_svideo',
  'settings_overscan',
  'settings_phone',
  'settings_power',
  'settings_remote',
  'settings_system_daydream',
  'settings_voice',
  'share',
  'shop',
  'shop_two',
  'shopping_basket',
  'shopping_cart',
  'short_text',
  'show_chart',
  'shuffle',
  'signal_cellular_4_bar',
  'signal_cellular_connected_no_internet_4_bar',
  'signal_cellular_no_sim',
  'signal_cellular_null',
  'signal_cellular_off',
  'signal_wifi_4_bar',
  'signal_wifi_4_bar_lock',
  'signal_wifi_off',
  'sim_card',
  'sim_card_alert',
  'skip_next',
  'skip_previous',
  'slideshow',
  'slow_motion_video',
  'smartphone',
  'smoke_free',
  'smoking_rooms',
  'sms',
  'sms_failed',
  'snooze',
  'sort',
  'sort_by_alpha',
  'spa',
  'space_bar',
  'speaker',
  'speaker_group',
  'speaker_notes',
  'speaker_notes_off',
  'speaker_phone',
  'spellcheck',
  'star',
  'star_border',
  'star_half',
  'stars',
  'stay_current_landscape',
  'stay_current_portrait',
  'stay_primary_landscape',
  'stay_primary_portrait',
  'stop',
  'stop_screen_share',
  'storage',
  'store',
  'store_mall_directory',
  'straighten',
  'streetview',
  'strikethrough_s',
  'style',
  'subdirectory_arrow_left',
  'subdirectory_arrow_right',
  'subject',
  'subscriptions',
  'subtitles',
  'subway',
  'supervisor_account',
  'surround_sound',
  'swap_calls',
  'swap_horiz',
  'swap_vert',
  'swap_vertical_circle',
  'switch_camera',
  'switch_video',
  'sync',
  'sync_disabled',
  'sync_problem',
  'system_update',
  'system_update_alt',
  'tab',
  'tab_unselected',
  'tablet',
  'tablet_android',
  'tablet_mac',
  'tag_faces',
  'tap_and_play',
  'terrain',
  'text_fields',
  'text_format',
  'textsms',
  'texture',
  'theaters',
  'thumb_down',
  'thumb_up',
  'thumbs_up_down',
  'time_to_leave',
  'timelapse',
  'timeline',
  'timer',
  'timer_10',
  'timer_3',
  'timer_off',
  'title',
  'toc',
  'today',
  'toll',
  'tonality',
  'touch_app',
  'toys',
  'track_changes',
  'traffic',
  'train',
  'tram',
  'transfer_within_a_station',
  'transform',
  'translate',
  'trending_down',
  'trending_flat',
  'trending_up',
  'tune',
  'turned_in',
  'turned_in_not',
  'tv',
  'unarchive',
  'undo',
  'unfold_less',
  'unfold_more',
  'update',
  'usb',
  'verified_user',
  'vertical_align_bottom',
  'vertical_align_center',
  'vertical_align_top',
  'vibration',
  'video_call',
  'video_label',
  'video_library',
  'videocam',
  'videocam_off',
  'videogame_asset',
  'view_agenda',
  'view_array',
  'view_carousel',
  'view_column',
  'view_comfy',
  'view_compact',
  'view_day',
  'view_headline',
  'view_list',
  'view_module',
  'view_quilt',
  'view_stream',
  'view_week',
  'vignette',
  'visibility',
  'visibility_off',
  'voice_chat',
  'voicemail',
  'volume_down',
  'volume_mute',
  'volume_off',
  'volume_up',
  'vpn_key',
  'vpn_lock',
  'wallpaper',
  'warning',
  'watch',
  'watch_later',
  'wb_auto',
  'wb_cloudy',
  'wb_incandescent',
  'wb_iridescent',
  'wb_sunny',
  'wc',
  'web',
  'web_asset',
  'weekend',
  'whatshot',
  'widgets',
  'wifi',
  'wifi_lock',
  'wifi_tethering',
  'work',
  'wrap_text',
  'youtube_searched_for',
  'zoom_in',
  'zoom_out',
  'zoom_out_map'
]
