import React from 'react'
import { AccordianWrapper } from './styles'
import PropTypes from 'prop-types'

const Collapse = ({ children }) => {
  return <AccordianWrapper>{children}</AccordianWrapper>
}

Collapse.ProtoTypes = {
  children: PropTypes.elementType | PropTypes.string
}
Collapse.defaultProps = {
  children: ''
}

export default Collapse
