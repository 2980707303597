import styled, { css } from 'styled-components'
import { switchButtonTheme } from '../../utils/styles/theme'

const SwitchButtonTabWrapper = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  padding: 8px 0;
  display: flex;
  margin-bottom: 15px;
  .flex-1 {
    padding: 0 4px;
    flex: 1;
    display: flex;
    align-items: center;
  }
`

const ToggleBtnIconWrapper = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: ${(props) => (props.isLabel ? '4px' : '0')};
`
const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover,
  &.active {
    background-color: ${(props) => (props.unstyled ? 'transparent' : '#fff')};
    box-shadow: ${(props) =>
      !props.unstyled ? '0px 4px 4px rgba(0, 0, 0, 0.03)' : 'none'};
    color: ${switchButtonTheme.ACTIVE_COLOR};
    ${(props) =>
      props.isIcon &&
      css`
        svg {
          fill: ${switchButtonTheme.ACTIVE_COLOR};
          stroke: ${switchButtonTheme.ACTIVE_COLOR};
        }
      `}
  }
  ${(props) =>
    props.unstyled &&
    `background-color: transparent;
     border: none;`}
`
export { SwitchButtonTabWrapper, ToggleBtnIconWrapper, ButtonWrapper }
