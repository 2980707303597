import React from 'react'
import * as iconDefs from './Icons'
import FontIconPicker from '@fonticonpicker/react-fonticonpicker'
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css'
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css'
import styled from 'styled-components'

function IconPicker({ handleChange, value, theme, ...props }) {
  const renderImage = (value) => <i className={value} />

  return (
    <PickerWrapper theme={theme}>
      <FontIconPicker
        icons={iconDefs.fontAwesome}
        value={value}
        onChange={handleChange}
        renderFunc={renderImage}
        theme={theme}
        {...props}
      />
    </PickerWrapper>
  )
}

const PickerWrapper = styled.div`
  > .rfip {
    margin: 0;
    width: 100%;
    /* Dropdown */
    > .rfipdropdown {
      left: 0 !important;
      width: 100%;
      &.rfipdropdown--${(props) => props.theme} {
        box-shadow: none;
        width: 100%;
        border: 1px solid #e8e8e8;
        .rfipicons__ibox {
          background-color: #fff;
        }
      }
    }
    /* Icon select dropdown button*/
    > .rfipbtn {
      width: 100%;
      min-height: 38px;
      /* Input placeholder */
      .rfipbtn__current .rfipbtn__icon--empty {
        text-transform: capitalize;
        font-style: normal;
        color: hsl(0, 0%, 50%);
      }
      &.rfipbtn--${(props) => props.theme} {
        :focus,
        :active {
          box-shadow: none;
          border: 1px solid rgb(14, 100, 255);
        }
        .rfipbtn__button {
          /* dropdown icon */
          i {
            font-size: 20px;
          }
          border-left: none;
          background-color: transparent;
        }
      }
    }
  }
`

IconPicker.defaultProps = {
  theme: 'default'
}

export default IconPicker
