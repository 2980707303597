import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'
import { useClickOutside } from '../../utils/hooks/useClickOutside'

const PLACEMENT = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom'
}

const PopOver = ({ children, placement, component }) => {
  const [showBlock, setShowBlock] = useState(false)

  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  const wrapperRef = useRef(null)
  const blockRef = useRef(null)

  const wrapperNode = wrapperRef?.current?.getBoundingClientRect()
  const blockNode = blockRef?.current?.getBoundingClientRect()

  const getMaxValueKey = (obj) =>
    Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b))

  const preventOffScreen = (placement) => {
    const Dimensions = {
      right: windowWidth - wrapperNode?.right,
      left: wrapperNode?.left,
      top: wrapperNode?.top,
      bottom: windowHeight - wrapperNode?.bottom
    }
    const check = [PLACEMENT.TOP, PLACEMENT.BOTTOM].find(
      (data) => data === placement
    )
      ? Dimensions[placement] > blockNode?.height
      : Dimensions[placement] > blockNode?.width

    if (check) {
      const style = handlePositon(placement)
      return style
    } else {
      const result = getMaxValueKey(Dimensions)
      const style = handlePositon(result)
      return style
    }
  }

  const handlePositon = (position = PLACEMENT.TOP) => {
    switch (position) {
      case PLACEMENT.TOP:
        return {
          visibility: showBlock ? 'visible' : 'hidden',
          bottom: `${wrapperNode?.height + 7}px`,
          PLACEMENT: PLACEMENT.TOP
        }
      case PLACEMENT.BOTTOM:
        return {
          visibility: showBlock ? 'visible' : 'hidden',
          top: `${wrapperNode?.height + 7}px`,
          PLACEMENT: PLACEMENT.BOTTOM
        }
      case PLACEMENT.LEFT:
        return {
          visibility: showBlock ? 'visible' : 'hidden',
          right: `${wrapperNode?.width + 7}px`,
          PLACEMENT: PLACEMENT.LEFT
        }
      case PLACEMENT.RIGHT:
        return {
          visibility: showBlock ? 'visible' : 'hidden',
          left: `${wrapperNode?.width + 7}px`,
          PLACEMENT: PLACEMENT.RIGHT
        }
      default:
        return {
          visibility: showBlock ? 'visible' : 'hidden',
          top: `${wrapperNode?.height + 7}px`,
          PLACEMENT: PLACEMENT.TOP
        }
    }
  }

  const domNode = useClickOutside(wrapperRef, () => {
    setShowBlock(false)
  })
  const updatedStyle = preventOffScreen(placement)

  return (
    <ChildrenWrapper ref={domNode}>
      <Button
        onClick={() => setShowBlock((showBlock) => !showBlock)}
        variant='primary'
      >
        {children}
      </Button>
      <PopoverBlock
        style={updatedStyle}
        ref={blockRef}
        className={`makeui-popover-placement-${updatedStyle.PLACEMENT}`}
      >
        {component()}
      </PopoverBlock>
    </ChildrenWrapper>
  )
}

const ChildrenWrapper = styled.div`
  position: relative;
  display: inline-flex;
`

const PopoverBlock = styled.div`
  visibility: hidden;
  position: absolute;
  right: 0;
  background-color: black;
  color: white;
  border-radius: 2px;
  padding: 5px;
  box-sizing: border-box;
  z-index: 99999;
  display: flex;

  /* LEFT */
  &.makeui-popover-placement-left:before {
    content: ' ';
    position: absolute;
    right: -5px;
    top: 5px;
    border-top: 5px solid transparent;
    border-right: none;
    border-left: 5px solid black;
    border-bottom: 5px solid transparent;
  }

  /* Right */
  &.makeui-popover-placement-right:before {
    content: ' ';
    position: absolute;
    left: -5px;
    top: 5px;
    border-top: 5px solid transparent;
    border-right: 5px solid black;
    border-left: none;
    border-bottom: 5px solid transparent;
  }
  /* Top */
  &.makeui-popover-placement-top:before {
    content: ' ';
    position: absolute;
    bottom: -5px;
    left: 5px;
    border-top: 5px solid black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: none;
  }
  /* Bottom */
  &.makeui-popover-placement-bottom:before {
    content: ' ';
    position: absolute;
    left: 5px;
    top: -5px;
    border-top: none;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid black;
  }
`

PopOver.defaltProps = {
  placement: PLACEMENT.TOP
}

PopOver.propTypes = {
  component: PropTypes.func
}
export default PopOver

/* 
CHECK Prop component is type "Func/Component" 
{typeof component === "function" ? component() : null}
*/

/*
console.log({ placement, Dimensions, blockNodeW: blockNode?.width, check, windowWidth, windowHeight })
*/
