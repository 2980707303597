import React from 'react'

import PropTypes from 'prop-types'
import { AvatarStyled } from './styles'

export const Avatar = ({ img, initials, className }) => {
  return (
    <AvatarStyled className={className}>
      {img ? <img src={img} alt='' /> : <p>{initials}</p>}
    </AvatarStyled>
  )
}

Avatar.propTypes = {
  /* sets the image source */
  img: PropTypes.string,
  /* sets the string if image is not available */
  initials: PropTypes.string,
  classes: PropTypes.string
}

Avatar.defaultProps = {
  img: '',
  initials: '',
  classes: ''
}

export default Avatar
