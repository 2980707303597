import React, { Fragment } from 'react'

export const getIcons = () => [
  {
    id: 0,
    name: 'warning',
    svg: (
      <Fragment>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z' />
      </Fragment>
    ),
    viewBox: '0 0 24 24',
    height: '24px',
    width: '24px'
  },
  {
    id: 1,
    name: 'text-tool',
    svg: (
      <Fragment>
        <path d='M21.5 18h-1.316L13.432.638A1 1 0 0 0 12.5 0h-2a1 1 0 0 0-.932.638L2.816 18H1.5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2H4.962l2.722-7h7.632l2.723 7H16.5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2ZM8.462 9l2.722-7h.632l2.722 7H8.462Z' />
      </Fragment>
    ),
    viewBox: '0 0 22 20',
    width: '22px',
    height: '20px'
  },
  {
    id: 2,
    name: 'image-tool',
    svg: (
      <Fragment>
        <path d='M1 20h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1Zm2.414-2L8 13.414l1.293 1.293a1 1 0 0 0 1.414 0L15 10.414l3 3V18H3.414ZM18 2v8.586l-2.293-2.293a1 1 0 0 0-1.414 0L10 12.586l-1.293-1.293a1 1 0 0 0-1.414 0L2 16.586V2h16ZM4 6.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Z' />
      </Fragment>
    ),
    viewBox: '0 0 20 20',
    height: '20px',
    width: '20px'
  },
  {
    id: 3,
    name: 'button-tool',
    svg: (
      <Fragment>
        <path
          fillRule='evenodd'
          d='M18.4 0C21.487 0 24 3.141 24 7s-2.513 7-5.6 7H5.6C2.513 14 0 10.859 0 7s2.513-7 5.6-7h12.8Zm0 2H5.6c-2.206 0-4 2.243-4 5s1.794 5 4 5h12.8c2.206 0 4-2.243 4-5s-1.794-5-4-5Zm-.8 4v2H6.4V6h11.2Z'
          clipRule='evenodd'
        />
      </Fragment>
    ),
    viewBox: '0 0 24 14',
    width: '24px',
    height: '14px'
  },
  {
    id: 4,
    name: 'elements-tool',
    svg: (
      <Fragment>
        <path d='m.484 15.857 10 6c.027.016.057.023.084.036.027.013.057.026.087.038a.893.893 0 0 0 .688 0c.03-.012.058-.024.087-.038.029-.014.057-.02.084-.036l10-6a1 1 0 0 0 .3-1.438l-10-14c-.013-.018-.035-.024-.049-.04a.962.962 0 0 0-1.53 0c-.014.016-.036.022-.049.04l-10 14a1 1 0 0 0 .3 1.438H.484Zm11.515 3.377V4.121L19.556 14.7 12 19.234Zm-2-15.113v15.113L2.442 14.7 9.999 4.12Z' />
      </Fragment>
    ),
    viewBox: '0 0 22 22',
    height: '22px',
    width: '22px'
  },
  {
    id: 5,
    name: 'addons-tool',
    svg: (
      <Fragment>
        <path d='M20.775.5H6.645c-1.784 0-2.718.92-2.718 2.7v4.64c.273-.041.545-.066.818-.066.28 0 .553.025.826.074V3.35c0-.804.42-1.201 1.18-1.201h13.916c.76 0 1.181.397 1.181 1.2v9.785c0 .804-.42 1.201-1.18 1.201H10.03a4.644 4.644 0 0 1-.627 1.649h11.372c1.792 0 2.725-.928 2.725-2.7V3.2C23.5 1.428 22.567.5 20.775.5Zm-16.022 17c2.313 0 4.253-1.939 4.253-4.275 0-2.336-1.924-4.266-4.253-4.266S.5 10.889.5 13.225C.5 15.57 2.424 17.5 4.753 17.5Zm-2.7-4.275c0-.34.23-.572.57-.572h1.56v-1.557c0-.34.223-.572.57-.572s.57.224.57.572v1.557h1.56c.34 0 .562.232.562.572 0 .348-.223.572-.561.572H5.323v1.574c0 .34-.223.563-.57.563s-.57-.223-.57-.563v-1.574h-1.56c-.34 0-.57-.224-.57-.572Z' />
      </Fragment>
    ),
    viewBox: '0 0 24 18',
    width: '24px',
    height: '18px'
  },
  {
    id: 6,
    name: 'layers-tool',
    svg: (
      <Fragment>
        <path d='M.149 9.525A1 1 0 0 0 1 10h20a1 1 0 0 0 .895-1.447l-4-8A1 1 0 0 0 17 0H5a1 1 0 0 0-.895.553l-4 8a1 1 0 0 0 .044.972ZM5.618 2h10.764l3 6H2.618l3-6ZM22 13a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h20a1 1 0 0 1 1 1Zm0 4a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h20a1 1 0 0 1 1 1Z' />
      </Fragment>
    ),
    viewBox: '0 0 22 18',
    width: '22px',
    height: '18px'
  },

  {
    id: 7,
    name: 'template-tool',
    svg: (
      <Fragment>
        <path d='M1 20h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1ZM2 8h2v10H2V8Zm16 10H6V8h12v10ZM2 2h16v4H2V2Z' />
      </Fragment>
    ),
    viewBox: '0 0 22 22',
    width: '22px',
    height: '22px'
  },
  {
    id: 8,
    name: 'colors-tool',
    svg: (
      <Fragment>
        <path d='M11.817 19.5c3.845 0 6.411-1.413 6.411-3.5 0-1.869-1.534-2.286-1.534-3.387 0-1.517 5.306-1.82 5.306-5.72C22 3.043 18.174.5 12.493.5 5.032.5 0 4.37 0 10.166 0 15.744 4.776 19.5 11.817 19.5Zm0-1.622c-6.182 0-10.255-3.092-10.255-7.712 0-4.838 4.328-8.044 10.931-8.044 4.795 0 7.945 1.935 7.945 4.771 0 3.31-5.342 2.865-5.342 5.73 0 1.622 1.57 2.134 1.57 3.253 0 1.205-1.908 2.002-4.849 2.002Zm.758-10.985c.859 0 1.535-.711 1.535-1.603 0-.882-.676-1.593-1.535-1.593-.84 0-1.543.711-1.543 1.593 0 .892.703 1.603 1.543 1.603ZM7.955 8.43c1.05 0 1.908-.882 1.908-2.001 0-1.091-.868-1.964-1.909-1.964-1.077 0-1.936.873-1.936 1.964 0 1.119.859 2.001 1.936 2.001Zm8.794-.93c.685 0 1.233-.578 1.233-1.3 0-.71-.548-1.29-1.233-1.29-.694 0-1.26.58-1.26 1.29 0 .722.566 1.3 1.26 1.3ZM4.612 11.466c.84 0 1.516-.721 1.516-1.594 0-.873-.667-1.575-1.516-1.575-.831 0-1.516.702-1.516 1.575s.685 1.594 1.516 1.594Zm6.457 4.173c1.388 0 2.53-1.185 2.53-2.637 0-1.432-1.142-2.618-2.53-2.618-1.389 0-2.53 1.186-2.53 2.618 0 1.452 1.141 2.637 2.53 2.637Zm0-1.1c-.813 0-1.47-.683-1.47-1.537 0-.939.693-1.498 1.451-1.508.776-.01 1.489.54 1.489 1.508 0 .854-.649 1.537-1.47 1.537Z' />
      </Fragment>
    ),
    viewBox: '0 0 22 20',
    width: '22px',
    height: '20px'
  },
  {
    id: 9,
    name: 'addons-2-tool',
    svg: (
      <Fragment>
        <path d='M9.454 8a1.454 1.454 0 1 1-2.908 0 1.454 1.454 0 0 1 2.908 0Zm4.364-2.285v4.57a2.903 2.903 0 1 1-3.533 3.533h-4.57a2.903 2.903 0 1 1-3.533-3.533v-4.57a2.903 2.903 0 1 1 3.533-3.533h4.57a2.903 2.903 0 1 1 3.533 3.533ZM11.636 2.91a1.454 1.454 0 1 0 2.909 0 1.454 1.454 0 0 0-2.909 0Zm.727 7.375v-4.57a2.91 2.91 0 0 1-2.078-2.078h-4.57a2.909 2.909 0 0 1-2.078 2.078v4.57a2.91 2.91 0 0 1 2.078 2.078h4.57a2.91 2.91 0 0 1 2.078-2.078ZM2.91 4.364a1.454 1.454 0 1 0 0-2.909 1.454 1.454 0 0 0 0 2.909Zm1.454 8.726a1.455 1.455 0 1 0-2.91 0 1.455 1.455 0 0 0 2.91 0Zm8.726-1.454a1.455 1.455 0 1 0 0 2.91 1.455 1.455 0 0 0 0-2.91Z' />
      </Fragment>
    ),
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px'
  },
  {
    id: 10,
    name: 'language-tool',
    svg: (
      <Fragment>
        <path d='M8 0a8 8 0 1 0 8 8 8.01 8.01 0 0 0-8-8ZM1.497 8.727h2.159a11.503 11.503 0 0 0 1.313 5.07 6.556 6.556 0 0 1-3.472-5.07Zm2.159-1.454H1.497a6.556 6.556 0 0 1 3.472-5.07 11.503 11.503 0 0 0-1.313 5.07Zm3.617 7.03c-1.05-.681-2.018-2.76-2.163-5.576h2.163v5.575Zm0-7.03H5.11c.145-2.816 1.113-4.895 2.163-5.576v5.576Zm1.454 7.03V8.727h2.163c-.145 2.816-1.113 4.895-2.163 5.575Zm0-7.03V1.697c1.05.681 2.018 2.76 2.163 5.576H8.727Zm2.303 6.523a11.502 11.502 0 0 0 1.314-5.069h2.159a6.556 6.556 0 0 1-3.473 5.07Zm1.314-6.523a11.502 11.502 0 0 0-1.313-5.07 6.556 6.556 0 0 1 3.471 5.07h-2.158Z' />
      </Fragment>
    ),
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px'
  },
  {
    id: 11,
    name: 'settings-tool',
    svg: (
      <Fragment>
        <path d='M8 5.09a2.909 2.909 0 1 0 0 5.819 2.909 2.909 0 0 0 0-5.818Zm0 4.365a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm6.538-3.637h-1.145c-.006-.016-.027-.078-.034-.094l.807-.807a1.462 1.462 0 0 0 0-2.067l-1.018-1.018a1.455 1.455 0 0 0-1.592-.317 1.455 1.455 0 0 0-.473.317l-.85.795-.051-.023V1.46A1.462 1.462 0 0 0 8.72 0H7.28a1.463 1.463 0 0 0-1.462 1.461v1.132l-.062.036-.032.012-.804-.807a1.455 1.455 0 0 0-1.592-.318 1.455 1.455 0 0 0-.474.317L1.836 2.852a1.463 1.463 0 0 0 0 2.067l.8.83c-.01.022-.024.047-.032.068H1.462A1.463 1.463 0 0 0 0 7.28v1.442a1.463 1.463 0 0 0 1.462 1.46h1.145c.006.017.026.079.034.095l-.808.807a1.463 1.463 0 0 0 0 2.067l1.019 1.018a1.497 1.497 0 0 0 2.067 0l.81-.81.088.04v1.142A1.463 1.463 0 0 0 7.28 16h1.44a1.463 1.463 0 0 0 1.462-1.461v-1.133l.094-.047.806.807a1.496 1.496 0 0 0 2.068 0l1.018-1.018a1.463 1.463 0 0 0 0-2.067l-.8-.832c.01-.02.024-.045.031-.067h1.14A1.462 1.462 0 0 0 16 8.72V7.279a1.463 1.463 0 0 0-1.462-1.46Zm0 2.91h-1.14a1.527 1.527 0 0 0-1.067 2.574l.806.817-1.028 1.018-.807-.806a1.526 1.526 0 0 0-2.575 1.065l-.007 1.15-1.447-.007v-1.143a1.486 1.486 0 0 0-.95-1.368 1.554 1.554 0 0 0-.594-.119 1.454 1.454 0 0 0-1.03.422l-.818.806-1.018-1.028.805-.806a1.527 1.527 0 0 0-1.065-2.576l-1.148-.006.007-1.448h1.14A1.527 1.527 0 0 0 3.67 4.698l-.806-.817L3.89 2.863l.807.806a1.484 1.484 0 0 0 1.62.305l.013-.005a1.484 1.484 0 0 0 .942-1.365l.007-1.15 1.447.007v1.143a1.455 1.455 0 0 0 .893 1.341 1.482 1.482 0 0 0 1.682-.276l.817-.806 1.018 1.028-.805.806a1.528 1.528 0 0 0 1.065 2.576h1.141l.008.006-.008 1.448Z' />
      </Fragment>
    ),
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px'
  },
  {
    id: 12,
    name: 'search',
    svg: (
      <Fragment>
        <path
          fill='#000'
          d='M4.436 8.966a4.4 4.4 0 0 0 2.666-.897l2.982 2.988c.1.1.24.159.387.159a.534.534 0 0 0 .545-.551.53.53 0 0 0-.153-.387l-2.97-2.976A4.387 4.387 0 0 0 8.87 4.53 4.447 4.447 0 0 0 4.436.094 4.447 4.447 0 0 0 0 4.53a4.444 4.444 0 0 0 4.436 4.436Zm0-.739C2.408 8.227.744 6.557.744 4.53.744 2.509 2.408.839 4.436.839c2.027 0 3.691 1.67 3.691 3.691 0 2.027-1.664 3.697-3.691 3.697Z'
        />
      </Fragment>
    ),
    viewBox: '0 0 12 12',
    width: '12px',
    height: '12px'
  },
  {
    id: 13,
    name: 'text-left',
    svg: (
      <Fragment>
        <path
          strokeWidth='.2'
          d='M15.75 1.4a.65.65 0 1 0 0-1.3h-15a.65.65 0 1 0 0 1.3h15ZM9 5.15a.65.65 0 1 0 0-1.3H.818a.65.65 0 1 0 0 1.3H9Zm6.75 3.75a.65.65 0 1 0 0-1.3h-15a.65.65 0 1 0 0 1.3h15ZM9 12.65a.65.65 0 0 0 0-1.3H.818a.65.65 0 0 0 0 1.3H9Z'
        />
      </Fragment>
    ),
    viewBox: '0 0 17 13',
    width: '17px',
    height: '13px'
  },
  {
    id: 14,
    name: 'text-right',
    svg: (
      <Fragment>
        <path
          strokeWidth='.2'
          d='M.75 1.4a.65.65 0 1 1 0-1.3h15a.65.65 0 1 1 0 1.3h-15ZM7.5 5.15a.65.65 0 1 1 0-1.3h8.182a.65.65 0 1 1 0 1.3H7.5ZM.75 8.9a.65.65 0 1 1 0-1.3h15a.65.65 0 1 1 0 1.3h-15Zm6.75 3.75a.65.65 0 0 1 0-1.3h8.182a.65.65 0 0 1 0 1.3H7.5Z'
        />
      </Fragment>
    ),
    viewBox: '0 0 17 13',
    width: '17px',
    height: '13px'
  },
  {
    id: 15,
    name: 'text-center',
    svg: (
      <Fragment>
        <path
          strokeWidth='.2'
          d='M1.25 1.4a.65.65 0 1 1 0-1.3h15a.65.65 0 1 1 0 1.3h-15Zm3.409 3.75a.65.65 0 1 1 0-1.3h8.182a.65.65 0 1 1 0 1.3H4.66ZM1.25 8.9a.65.65 0 1 1 0-1.3h15a.65.65 0 1 1 0 1.3h-15Zm3.409 3.75a.65.65 0 0 1 0-1.3h8.182a.65.65 0 0 1 0 1.3H4.66Z'
        />
      </Fragment>
    ),
    viewBox: '0 0 17 13',
    width: '17px',
    height: '13px'
  },
  {
    id: 16,
    name: 'text-justify',
    svg: (
      <Fragment>
        <path
          strokeWidth='.2'
          d='M1.25 1.4a.65.65 0 1 1 0-1.3h15a.65.65 0 1 1 0 1.3h-15Zm0 3.75a.65.65 0 1 1 0-1.3h15.183a.65.65 0 1 1 0 1.3H1.25Zm0 3.75a.65.65 0 1 1 0-1.3h15a.65.65 0 1 1 0 1.3h-15Zm0 3.75a.65.65 0 0 1 0-1.3h15.183a.65.65 0 0 1 0 1.3H1.25Z'
        />
      </Fragment>
    ),
    viewBox: '0 0 18 13',
    width: '18px',
    height: '13px'
  },
  {
    id: 17,
    name: 'align-horizontal-center',
    svg: (
      <Fragment>
        <path d='M7.607.472a.79.79 0 0 1 .787.788v3.15h5.119a1.18 1.18 0 0 1 1.181 1.18 1.18 1.18 0 0 1-1.181 1.182H8.394v3.15h2.756a1.18 1.18 0 0 1 1.182 1.181 1.18 1.18 0 0 1-1.182 1.181H8.394v3.15a.79.79 0 0 1-.787.788.79.79 0 0 1-.788-.788v-3.15H4.063a1.18 1.18 0 0 1-1.181-1.18 1.18 1.18 0 0 1 1.181-1.182h2.756v-3.15H1.701A1.18 1.18 0 0 1 .519 5.591 1.18 1.18 0 0 1 1.701 4.41h5.118V1.26a.79.79 0 0 1 .788-.788Z' />
      </Fragment>
    ),
    viewBox: '0 0 15 17',
    width: '15px',
    height: '17px'
  },
  {
    id: 18,
    name: 'align-horizontal-right',
    svg: (
      <Fragment>
        <path d='M1.54 16.221a.79.79 0 0 1-.788-.787V1.259a.79.79 0 0 1 .787-.787.79.79 0 0 1 .788.787v14.175a.79.79 0 0 1-.788.787Zm13.78-6.3H5.083a1.18 1.18 0 0 0-1.181 1.182 1.18 1.18 0 0 0 1.181 1.181h10.238a1.18 1.18 0 0 0 1.18-1.181 1.18 1.18 0 0 0-1.18-1.181ZM10.596 4.41H5.083A1.18 1.18 0 0 0 3.902 5.59a1.18 1.18 0 0 0 1.181 1.182h5.513a1.18 1.18 0 0 0 1.18-1.182 1.18 1.18 0 0 0-1.18-1.18Z' />
      </Fragment>
    ),
    viewBox: '0 0 17 17',
    width: '17px',
    height: '17px'
  },
  {
    id: 19,
    name: 'align-vertical-bottom',
    svg: (
      <Fragment>
        <path d='M16.46 1.26v14.174a.79.79 0 0 1-.787.787.79.79 0 0 1-.787-.787V1.259a.79.79 0 0 1 .787-.787.79.79 0 0 1 .788.787ZM.71 11.102a1.18 1.18 0 0 0 1.182 1.181H12.13a1.18 1.18 0 0 0 1.18-1.181 1.18 1.18 0 0 0-1.18-1.181H1.892a1.18 1.18 0 0 0-1.181 1.18ZM5.437 5.59a1.18 1.18 0 0 0 1.181 1.182h5.513a1.18 1.18 0 0 0 1.18-1.182 1.18 1.18 0 0 0-1.18-1.18H6.617a1.18 1.18 0 0 0-1.181 1.18Z' />
      </Fragment>
    ),
    viewBox: '0 0 17 17',
    width: '17px',
    height: '17px'
  },
  {
    id: 20,
    name: 'align-vertical-center',
    svg: (
      <Fragment>
        <path d='M15.613 6.56h-3.15V3.802a1.18 1.18 0 0 0-1.181-1.181 1.18 1.18 0 0 0-1.181 1.181V6.56H6.95V1.441A1.18 1.18 0 0 0 5.77.26a1.18 1.18 0 0 0-1.182 1.18v5.12H1.312a.79.79 0 0 0-.787.787.79.79 0 0 0 .787.787h3.276v5.119a1.18 1.18 0 0 0 1.182 1.181 1.18 1.18 0 0 0 1.18-1.181V8.134h3.15v2.757a1.18 1.18 0 0 0 1.182 1.18 1.18 1.18 0 0 0 1.181-1.18V8.134h3.15a.79.79 0 0 0 .788-.787.79.79 0 0 0-.788-.788Z' />
      </Fragment>
    ),
    viewBox: '0 0 17 15',
    width: '17px',
    height: '15px'
  },
  {
    id: 21,
    name: 'align-vertical-top',
    svg: (
      <Fragment>
        <path d='M16.422 1.26a.79.79 0 0 1-.788.788H1.46a.79.79 0 0 1-.788-.787.79.79 0 0 1 .788-.788h14.174a.79.79 0 0 1 .788.788ZM5.79 16.224a1.18 1.18 0 0 0 1.181-1.181V4.804a1.18 1.18 0 0 0-1.181-1.18 1.18 1.18 0 0 0-1.181 1.18v10.238a1.18 1.18 0 0 0 1.18 1.18Zm5.512-4.725a1.18 1.18 0 0 0 1.181-1.181V4.804a1.18 1.18 0 0 0-1.18-1.18 1.18 1.18 0 0 0-1.182 1.18v5.513a1.18 1.18 0 0 0 1.181 1.181Z' />
      </Fragment>
    ),
    viewBox: '0 0 17 17',
    width: '17px',
    height: '17px'
  },
  {
    id: 22,
    name: 'align-3',
    svg: (
      <Fragment>
        <path d='M.63 15.435a.79.79 0 0 1 .787-.788h14.175a.79.79 0 0 1 .788.788.79.79 0 0 1-.788.787H1.417a.79.79 0 0 1-.787-.787ZM11.26.473a1.18 1.18 0 0 0-1.181 1.18v10.238a1.18 1.18 0 0 0 1.181 1.181 1.18 1.18 0 0 0 1.181-1.18V1.653A1.18 1.18 0 0 0 11.261.473ZM5.749 5.198a1.18 1.18 0 0 0-1.182 1.18v5.513a1.18 1.18 0 0 0 1.182 1.181 1.18 1.18 0 0 0 1.18-1.18V6.378a1.18 1.18 0 0 0-1.18-1.181Z' />
      </Fragment>
    ),
    viewBox: '0 0 17 17',
    width: '17px',
    height: '17px'
  },
  {
    id: 23,
    name: 'distribute-horizontally',
    svg: (
      <Fragment>
        <path d='M7.4 11.3c0 .8-.6 1.4-1.4 1.4-.8 0-1.4-.6-1.4-1.4V4.5c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4v6.8zM10.8 15.5c-.4 0-.7-.3-.7-.7V1c0-.4.3-.7.7-.7s.7.3.7.7v13.8c0 .4-.3.7-.7.7zM1.1 15.5c-.4 0-.7-.3-.7-.7V1c0-.4.3-.7.7-.7s.7.3.7.7v13.8c0 .4-.3.7-.7.7z' />
      </Fragment>
    ),
    viewBox: '0 0 12 16',
    width: '12px',
    height: '16px'
  },
  {
    id: 24,
    name: 'distribute-vertically',
    svg: (
      <Fragment>
        <path d='M11.6 4.6c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.5 1.4H4.7c-.8 0-1.5-.6-1.5-1.4 0-.8.6-1.4 1.5-1.4h6.9zM1 1.8c-.4 0-.7-.3-.7-.7C.3.7.6.4 1 .4L15.1.3c.4 0 .7.3.7.7s-.3.7-.7.7L1 1.8zM1.1 11.7c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7l14.1-.1c.4 0 .7.3.7.7 0 .4-.3.7-.7.7l-14.1.1z' />
      </Fragment>
    ),
    viewBox: '0 0 16 12',
    width: '16px',
    height: '12px'
  },
  {
    id: 25,
    name: 'bear',
    svg: (
      <Fragment>
        <path d='M18 0a3.976 3.976 0 0 0-3.268 1.729 9.917 9.917 0 0 0-7.464 0 3.984 3.984 0 1 0-5.539 5.54A9.94 9.94 0 0 0 1 11c0 5.192 4.276 11 10 11s10-5.808 10-11a9.943 9.943 0 0 0-.729-3.731A3.984 3.984 0 0 0 18 0ZM2.667 5.478A1.978 1.978 0 0 1 2 4a2 2 0 0 1 2-2 1.978 1.978 0 0 1 1.478.667 10.3 10.3 0 0 0-2.811 2.811ZM11 20c-4.579 0-8-4.751-8-9a8 8 0 0 1 16 0c0 4.249-3.421 9-8 9Zm5.522-17.333A1.977 1.977 0 0 1 18 2a2 2 0 0 1 2 2 1.98 1.98 0 0 1-.667 1.478 10.408 10.408 0 0 0-2.811-2.811ZM15 14.5c0 1.038-.836 3-4 3s-4-1.962-4-3a1 1 0 0 1 2 0c.008.111.109.644 1 .882v-1.331l-1.383-2.625A.954.954 0 0 1 9.367 10h3.265a.955.955 0 0 1 .751 1.426L12 14.051v1.331c.891-.239.992-.778 1-.911a1.028 1.028 0 0 1 1.032-.952.984.984 0 0 1 .968.981Z' />
      </Fragment>
    ),
    viewBox: '0 0 22 22',
    width: '22px',
    height: '22px'
  },
  {
    id: 26,
    name: 'cat',
    svg: (
      <Fragment>
        <path d='M9.165 11.21A.75.75 0 0 1 9.791 10h2.418a.75.75 0 0 1 .626 1.21l-1.209 1.5a.813.813 0 0 1-1.252 0l-1.209-1.5ZM.051 14.317a1 1 0 0 1 .633-1.265l.363-.121A10.212 10.212 0 0 1 1 12V1A1 1 0 0 1 2.515.143l4.522 2.712A8.578 8.578 0 0 1 11 2a8.588 8.588 0 0 1 3.964.855L19.485.143A1 1 0 0 1 21 1v11c0 .314-.019.623-.047.93l.363.12a1.001 1.001 0 1 1-.632 1.9l-.119-.04a9.94 9.94 0 0 1-1.4 2.844l.541.54a1 1 0 0 1-1.414 1.415l-.442-.442a9.948 9.948 0 0 1-13.7 0l-.442.442a1 1 0 0 1-1.414-1.414l.541-.541a9.94 9.94 0 0 1-1.4-2.843c-.776.212-1.221-.111-1.384-.595Zm2.963-2.042 3.67-1.223a1.001 1.001 0 0 1 .632 1.9l-3.982 1.325c.215.72.532 1.405.939 2.037l3.02-3.02a1 1 0 0 1 1.414 1.414L5.563 17.85a7.952 7.952 0 0 0 10.874 0l-3.144-3.144a1 1 0 0 1 1.414-1.414l3.02 3.02a7.96 7.96 0 0 0 .939-2.037l-3.982-1.327a1.001 1.001 0 0 1 .632-1.9l3.67 1.223c0-.091.014-.181.014-.274V2.766l-3.485 2.09a1 1 0 0 1-1 .02A6.665 6.665 0 0 0 11 4a6.665 6.665 0 0 0-3.518.876 1 1 0 0 1-1-.02L3 2.767V12c0 .093.011.183.014.274Z' />
      </Fragment>
    ),
    viewBox: '0 0 23 22',
    width: '23px',
    height: '22px'
  },
  {
    id: 27,
    name: 'export',
    svg: (
      <Fragment>
        <path d='M1.6 14.4h9.601v-3.998a.8.8 0 0 1 1.6 0V15.2a.8.8 0 0 1-.8.8H.802A.8.8 0 0 1 0 15.2v-4.797a.8.8 0 0 1 1.6 0V14.4Zm4-6.396v3.198a.8.8 0 0 0 1.6 0V8.004a4.002 4.002 0 0 1 4.001-3.999h2.147l-.991 1.051a.8.8 0 1 0 1.165 1.097l2.246-2.381.016-.016a.833.833 0 0 0 .07-.112.745.745 0 0 0 .087-.137.794.794 0 0 0 0-.6.761.761 0 0 0-.086-.136.833.833 0 0 0-.07-.112l-.017-.016L13.522.258a.8.8 0 1 0-1.165 1.097l.991 1.051h-2.147a5.61 5.61 0 0 0-5.6 5.598Z' />
      </Fragment>
    ),
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px'
  },
  {
    id: 28,
    name: 'phone',
    svg: (
      <Fragment>
        <path d='M8.571 16c.38 0 .743-.153 1.01-.426.268-.273.419-.643.419-1.028V1.455c0-.386-.15-.756-.418-1.029A1.416 1.416 0 0 0 8.572 0H1.428c-.38 0-.743.153-1.01.426A1.468 1.468 0 0 0 0 1.455v13.09c0 .386.15.756.418 1.029.268.273.632.426 1.01.426h7.143ZM1.43 1.455H8.57v13.09H1.43V1.456ZM5.714 13.09a.737.737 0 0 1-.12.404.702.702 0 0 1-1.099.11.731.731 0 0 1-.155-.792.725.725 0 0 1 .263-.327.705.705 0 0 1 .902.09c.134.137.21.322.21.515Z' />
      </Fragment>
    ),
    viewBox: '0 0 10 16',
    width: '10px',
    height: '16px'
  },
  {
    id: 29,
    name: 'desktop',
    svg: (
      <Fragment>
        <path d='M17.182 0H.818A.828.828 0 0 0 .24.234.791.791 0 0 0 0 .8V12c0 .212.086.416.24.566.153.15.361.234.578.234h7.364v1.6H5.727a.828.828 0 0 0-.578.234.791.791 0 0 0 0 1.132c.153.15.361.234.578.234h6.546a.828.828 0 0 0 .578-.234.791.791 0 0 0 0-1.132.828.828 0 0 0-.578-.234H9.818v-1.6h7.364a.828.828 0 0 0 .578-.234A.791.791 0 0 0 18 12V.8a.791.791 0 0 0-.24-.566.828.828 0 0 0-.578-.234Zm-.818 11.2H1.636V1.6h14.728v9.6Z' />
      </Fragment>
    ),
    viewBox: '0 0 18 16',
    width: '18px',
    height: '16px'
  },
  {
    id: 30,
    name: 'document',
    svg: (
      <Fragment>
        <path d='M.751 12.517c0 1.428.704 2.14 2.12 2.14h7.259c1.415 0 2.119-.712 2.119-2.14V2.147C12.249.724 11.545 0 10.129 0H2.87C1.455 0 .751.725.751 2.146v10.37Zm1.1-.02V2.166c0-.684.363-1.066 1.074-1.066h7.15c.711 0 1.073.382 1.073 1.066v10.33c0 .683-.362 1.059-1.073 1.059h-7.15c-.711 0-1.073-.376-1.073-1.06Zm2.065-8.532h5.182a.419.419 0 0 0 .423-.43.406.406 0 0 0-.423-.411H3.916c-.246 0-.43.178-.43.41 0 .24.184.43.43.43Zm0 2.386h5.182a.419.419 0 0 0 .423-.431.406.406 0 0 0-.423-.41H3.916c-.246 0-.43.178-.43.41 0 .24.184.43.43.43Zm0 2.385H6.37c.24 0 .424-.184.424-.417a.417.417 0 0 0-.424-.423H3.916a.419.419 0 0 0-.43.423c0 .233.184.417.43.417Z' />
      </Fragment>
    ),
    viewBox: '0 0 13 15',
    width: '13px',
    height: '15px'
  },
  {
    id: 31,
    name: 'redo',
    svg: (
      <Fragment>
        <path
          fillRule='evenodd'
          d='M11.387 3.521H4.69C2.077 3.521 0 5.916 0 8.761 0 11.59 2.101 14 4.69 14h3.683a.7.7 0 1 0 0-1.4H4.69c-1.77 0-3.29-1.743-3.29-3.84 0-2.111 1.498-3.84 3.29-3.84h6.986l-2.56 2.711a.7.7 0 0 0 1.018.962L13.81 4.7a.7.7 0 0 0-.061-1.019L9.586.165a.7.7 0 0 0-.904 1.07l2.705 2.285v.001Z'
          clipRule='evenodd'
        />
      </Fragment>
    ),
    viewBox: '0 0 14 14',
    width: '14px',
    height: '14px'
  },
  {
    id: 32,
    name: 'undo',
    svg: (
      <Fragment>
        <path
          fillRule='evenodd'
          d='M2.613 3.521H9.31c2.613 0 4.69 2.395 4.69 5.24C14 11.59 11.899 14 9.31 14H5.627a.7.7 0 1 1 0-1.4H9.31c1.77 0 3.29-1.743 3.29-3.84 0-2.111-1.498-3.84-3.29-3.84H2.324l2.56 2.711a.7.7 0 0 1-1.018.962L.19 4.7a.7.7 0 0 1 .061-1.019L4.414.165a.7.7 0 0 1 .904 1.07L2.613 3.52v.001Z'
          clipRule='evenodd'
        />
      </Fragment>
    ),
    viewBox: '0 0 14 14',
    width: '14px',
    height: '14px'
  },
  {
    id: 33,
    name: 'close',
    svg: (
      <Fragment>
        <path d='M13.972 2.972 9.444 7.5l4.528 4.528a1.374 1.374 0 0 1-1.498 2.253 1.375 1.375 0 0 1-.446-.309L7.5 9.445l-4.528 4.527a1.376 1.376 0 1 1-1.944-1.944L5.556 7.5 1.028 2.972a1.375 1.375 0 1 1 1.944-1.944L7.5 5.556l4.528-4.528a1.374 1.374 0 1 1 1.944 1.944Z' />
      </Fragment>
    ),
    viewBox: '0 0 15 15',
    width: '15px',
    height: '15px'
  },
  {
    id: 34,
    name: 'create-new',
    svg: (
      <Fragment>
        <path d='M26.947 13.316v9.21a1.316 1.316 0 0 1-1.316 1.316H1.947a1.316 1.316 0 0 1-1.316-1.315v-9.21a1.316 1.316 0 0 1 2.632 0v7.894h21.053v-7.895a1.316 1.316 0 1 1 2.631 0ZM13.79.158a1.316 1.316 0 0 0-1.316 1.316v5.263H7.21a1.316 1.316 0 0 0 0 2.632h5.263v5.263a1.316 1.316 0 1 0 2.632 0V9.369h5.263a1.316 1.316 0 0 0 0-2.632h-5.263V1.474A1.316 1.316 0 0 0 13.789.158Z' />
      </Fragment>
    ),
    viewBox: '0 0 27 24',
    width: '27px',
    height: '24px'
  },
  {
    id: 35,
    name: 'templates',
    svg: (
      <Fragment>
        <path d='M.222 12.691a1.316 1.316 0 0 0 1.12.625h26.316a1.315 1.315 0 0 0 1.177-1.904L23.572.886a1.316 1.316 0 0 0-1.177-.728H6.605a1.316 1.316 0 0 0-1.178.728L.164 11.412a1.315 1.315 0 0 0 .058 1.28ZM7.418 2.79h14.163l3.948 7.894H3.47L7.418 2.79Zm21.556 14.474a1.316 1.316 0 0 1-1.316 1.315H1.342a1.316 1.316 0 0 1 0-2.631h26.316a1.315 1.315 0 0 1 1.316 1.316Zm0 5.263a1.315 1.315 0 0 1-1.316 1.315H1.342a1.316 1.316 0 1 1 0-2.631h26.316a1.316 1.316 0 0 1 1.316 1.316Z' />
      </Fragment>
    ),
    viewBox: '0 0 29 24',
    width: '29px',
    height: '24px'
  },
  {
    id: 36,
    name: 'chevron-left',
    svg: (
      <Fragment>
        <path d='M.988 6.712a.66.66 0 0 0 .205.483l5.808 5.677a.63.63 0 0 0 .477.197c.38 0 .673-.285.673-.666 0-.19-.08-.352-.197-.476L2.622 6.712l5.332-5.215a.702.702 0 0 0 .197-.476.657.657 0 0 0-.673-.667.652.652 0 0 0-.477.19L1.193 6.23a.648.648 0 0 0-.205.483Z' />
      </Fragment>
    ),
    viewBox: '0 0 9 14',
    width: '9px',
    height: '14px'
  },
  {
    id: 37,
    name: 'info',
    svg: (
      <Fragment>
        <path d='M5.918 11.019c2.759 0 5.044-2.28 5.044-5.044 0-2.76-2.285-5.044-5.049-5.044C3.154.93.874 3.216.874 5.975c0 2.763 2.285 5.044 5.044 5.044Zm0-.992A4.039 4.039 0 0 1 1.87 5.975a4.03 4.03 0 0 1 4.043-4.048 4.046 4.046 0 0 1 4.058 4.048 4.043 4.043 0 0 1-4.053 4.052Zm-.03-5.713a.662.662 0 0 0 .665-.664.664.664 0 1 0-1.328 0c0 .367.297.664.664.664Zm-.834 4.317h1.982c.22 0 .39-.156.39-.376a.38.38 0 0 0-.39-.376H6.49v-2.32c0-.292-.146-.483-.42-.483h-.927a.383.383 0 0 0-.391.376c0 .22.176.376.39.376h.499v2.05h-.586a.378.378 0 0 0-.386.377c0 .22.17.376.386.376Z' />
      </Fragment>
    ),
    viewBox: '0 0 11 12',
    width: '11px',
    height: '12px'
  },
  {
    id: 38,
    name: 'folder',
    svg: (
      <Fragment>
        <path d='M2.238 14h12.676C16.24 14 17 13.22 17 11.725V3.708c0-1.495-.759-2.275-2.238-2.275H7.427c-.577 0-.88-.109-1.275-.467l-.44-.39C5.22.126 4.87 0 4.112 0H1.95C.69 0 0 .709 0 2.135v9.59C0 13.236.759 14 2.238 14ZM.94 2.19c0-.827.41-1.24 1.19-1.24h1.753c.554 0 .85.117 1.26.491l.432.39c.478.428.857.569 1.623.569h7.54c.842 0 1.32.467 1.32 1.363v.6H.94V2.189Zm1.312 10.852c-.85 0-1.312-.468-1.312-1.364V5.274H16.06v6.404c0 .896-.479 1.364-1.32 1.364H2.252Z' />
      </Fragment>
    ),
    viewBox: '0 0 17 14',
    width: '17px',
    height: '14px'
  },
  {
    id: 39,
    name: 'add-folder',
    svg: (
      <Fragment>
        <path d='M16.977 8.594c2.109 0 3.882-1.758 3.882-3.883A3.898 3.898 0 0 0 16.977.828c-2.125 0-3.883 1.758-3.883 3.883a3.898 3.898 0 0 0 3.883 3.883ZM2.305 15.172h13.054c1.368 0 2.149-.781 2.149-2.281V9.477a4.01 4.01 0 0 1-.969 0v3.367c0 .898-.492 1.367-1.36 1.367H2.32c-.875 0-1.351-.469-1.351-1.367V6.422h11.508a3.916 3.916 0 0 1-.227-.914H.969v-2.18c0-.828.422-1.242 1.226-1.242H4c.57 0 .875.117 1.297.492l.445.39c.492.43.883.571 1.672.571h4.914c.078-.344.172-.633.336-.969H7.648c-.593 0-.906-.11-1.312-.468l-.453-.391c-.508-.453-.867-.578-1.649-.578H2.008C.71 1.133 0 1.843 0 3.273v9.618c0 1.515.781 2.28 2.305 2.28ZM14.508 4.71c0-.258.18-.414.422-.414h1.625V2.672c0-.242.164-.422.422-.422.257 0 .414.18.414.422v1.625h1.625c.25 0 .422.156.422.414s-.172.422-.422.422H17.39v1.625c0 .25-.157.422-.414.422-.258 0-.422-.172-.422-.422V5.133H14.93a.402.402 0 0 1-.422-.422Z' />
      </Fragment>
    ),
    viewBox: '0 0 21 16',
    width: '21px',
    height: '16px'
  },
  {
    id: 40,
    name: 'vertical-dots',
    svg: (
      <Fragment>
        <path d='M2.04 3.656a1.617 1.617 0 1 1 0-3.234 1.617 1.617 0 0 1 0 3.234ZM2.04 8.507a1.617 1.617 0 1 1 0-3.234 1.617 1.617 0 0 1 0 3.234ZM2.04 13.358a1.617 1.617 0 1 1 0-3.234 1.617 1.617 0 0 1 0 3.234Z' />
      </Fragment>
    ),
    viewBox: '0 0 4 14',
    width: '4px',
    height: '14px'
  },
  {
    id: 41,
    name: 'plus',
    svg: (
      <Fragment>
        <path
          fillRule='evenodd'
          d='M6.303 6.303V1.997a1.077 1.077 0 0 1 2.154 0v4.306h4.306a1.077 1.077 0 1 1 0 2.154H8.457v4.306a1.077 1.077 0 1 1-2.154 0V8.457H1.997a1.077 1.077 0 0 1 0-2.154h4.306Z'
          clipRule='evenodd'
        />
      </Fragment>
    ),
    viewBox: '0 0 14 14',
    width: '14px',
    height: '14px'
  },
  {
    id: 42,
    name: 'up-arrow',
    svg: (
      <Fragment>
        <path d='M5 13c.343 0 .587-.248.587-.606V3.331l-.046-1.466 1.972 2.257 1.504 1.542a.57.57 0 0 0 .416.165c.33 0 .567-.261.567-.599a.633.633 0 0 0-.178-.447L5.442.206A.587.587 0 0 0 5 0a.587.587 0 0 0-.442.206L.185 4.783A.64.64 0 0 0 0 5.23c0 .337.237.599.567.599a.56.56 0 0 0 .416-.165l1.504-1.542 1.979-2.264-.053 1.473v9.063c0 .358.244.606.587.606Z' />
      </Fragment>
    ),
    viewBox: '0 0 10 13',
    width: '10px',
    height: '13px'
  },
  {
    id: 43,
    name: 'down-arrow',
    svg: (
      <Fragment>
        <path d='M5 0c-.343 0-.587.248-.587.606v9.063l.046 1.466-1.972-2.257L.983 7.336a.57.57 0 0 0-.416-.165c-.33 0-.567.261-.567.599 0 .165.053.31.178.447l4.38 4.576A.586.586 0 0 0 5 13a.586.586 0 0 0 .442-.207l4.373-4.576A.64.64 0 0 0 10 7.77c0-.337-.237-.599-.567-.599a.56.56 0 0 0-.416.165L7.513 8.878l-1.979 2.264.053-1.473V.606C5.587.248 5.343 0 5 0Z' />
      </Fragment>
    ),
    viewBox: '0 0 10 13',
    width: '10px',
    height: '13px'
  },
  {
    id: 44,
    name: 'copy',
    svg: (
      <Fragment>
        <path d='M15.2 4.8H5.6a.8.8 0 0 0-.8.8v9.6a.8.8 0 0 0 .8.8h9.6a.8.8 0 0 0 .8-.8V5.6a.8.8 0 0 0-.8-.8Zm-.8 9.6h-8v-8h8v8Zm-11.2-4a.8.8 0 0 1-.8.8H.8a.8.8 0 0 1-.8-.8V.8A.8.8 0 0 1 .8 0h9.6a.8.8 0 0 1 .8.8v1.6a.8.8 0 0 1-1.6 0v-.8h-8v8h.8a.8.8 0 0 1 .8.8Z' />
      </Fragment>
    ),
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px'
  },
  {
    id: 45,
    name: 'delete',
    svg: (
      <Fragment>
        <path d='M14 2.667h-3.333v-2A.667.667 0 0 0 10 0H4.667A.667.667 0 0 0 4 .667v2H.667A.667.667 0 1 0 .667 4h.744l1.261 10.083a.666.666 0 0 0 .661.584h8a.667.667 0 0 0 .662-.584L13.255 4H14a.667.667 0 1 0 0-1.333ZM5.333 1.333h4v1.334h-4V1.333Zm5.412 12H3.922L2.755 4h9.156l-1.166 9.333Z' />
      </Fragment>
    ),
    viewBox: '0 0 15 15',
    width: '15px',
    height: '15px'
  },
  {
    id: 46,
    name: 'border-top',
    svg: (
      <Fragment>
        <path d='M20.3 21.6H3c-1.6 0-2.9-1.3-2.9-2.9V1.5h1.4v17.2c0 .8.6 1.4 1.4 1.4h17.2c.8 0 1.4-.6 1.4-1.4V1.5h1.4v17.2c.3 1.7-1 2.9-2.6 2.9z' />
        <path d='M21.9 3H1.5C.7 3 0 2.3 0 1.5S.7 0 1.5 0h20.4c.8 0 1.5.7 1.5 1.5S22.7 3 21.9 3z' />
      </Fragment>
    ),
    viewBox: '0 0 23.4 21.6',
    width: '23.4px',
    height: '21.6px'
  },
  {
    id: 47,
    name: 'chevron-down',
    svg: (
      <Fragment>
        <path d='M.21.219A.702.702 0 0 1 1.15.146l.08.073L7 6.199l5.77-5.98a.702.702 0 0 1 .938-.073l.081.073c.256.265.279.68.07.971l-.07.084-6.28 6.507a.702.702 0 0 1-.937.073L6.49 7.78.21 1.274a.766.766 0 0 1 0-1.055Z' />
      </Fragment>
    ),
    viewBox: '0 0 14 8',
    width: '14px',
    height: '8px'
  },
  {
    id: 47,
    name: 'clock',
    svg: (
      <Fragment>
        <path d='M12.9 12.7c-.1 0-.2 0-.3-.1l-4.3-2.3c-.2-.1-.3-.3-.3-.5V4.7c0-.3.3-.6.6-.6s.6.3.6.6v4.7l4 2.2c.3.2.4.5.2.8-.1.2-.3.3-.5.3z' />
        <path d='M9 18c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zM9 1.2C4.7 1.2 1.2 4.7 1.2 9s3.5 7.8 7.8 7.8 7.8-3.5 7.8-7.8S13.3 1.2 9 1.2z' />
      </Fragment>
    ),
    viewBox: '0 0 18 18',
    width: '18px',
    height: '18px'
  },
  {
    id: 48,
    name: 'question-mark',
    svg: (
      <Fragment>
        <path
          fillRule='evenodd'
          d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM8 5a1 1 0 0 0-.867.5 1 1 0 1 1-1.731-1A3 3 0 0 1 11 6a3.001 3.001 0 0 1-2 2.83V9a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1 1 1 0 1 0 0-2Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z'
          clipRule='evenodd'
        />
      </Fragment>
    ),
    viewBox: '0 0 16 16',
    width: '16px',
    height: '16px'
  },
  {
    id: 49,
    name: 'calendar',
    svg: (
      <Fragment>
        <path d='M17.7 1.4h-2.2V.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.9H12V.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.9H8.6V.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.9H5.1V.5c0-.3-.2-.5-.4-.5s-.5.2-.5.5v.9H2c-1.1 0-2 .9-2 2V17c0 1.1.9 2 2 2h15.7c1.1 0 2-.9 2-2V3.4c0-1.1-.9-2-2-2zM2 2.4h2.2v.9c0 .3.2.5.5.5s.5-.2.5-.5v-.9h2.5v.9c0 .3.2.5.5.5s.5-.2.5-.5v-.9h2.5v.9c0 .3.2.5.5.5s.5-.2.5-.5v-.9h2.5v.9c0 .3.2.5.5.5s.5-.2.5-.5v-.9h2.2c.6 0 1 .5 1 1v2.5H1V3.4c0-.6.4-1 1-1zM17.7 18H2c-.6 0-1-.4-1-1V6.9h17.7V17c0 .6-.5 1-1 1z' />
        <path d='M3.3 10h2.1c.3 0 .5-.2.5-.5S5.6 9 5.3 9h-2c-.3 0-.5.2-.5.5s.2.5.5.5zM3.3 12.8h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H3.3c-.3 0-.5.2-.5.5s.2.5.5.5zM5.3 14.5h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.3-.5-.6-.5zM10.9 9H8.8c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM16.4 9h-2.1c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM16.4 11.8h-2.1c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM16.4 14.5h-2.1c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM10.9 11.8H8.8c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM10.9 14.5H8.8c-.3 0-.5.2-.5.5s.2.5.5.5h2.1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z' />
      </Fragment>
    ),
    viewBox: '0 0 20 19',
    width: '20px',
    height: '19px'
  },
  {
    id: 50,
    name: 'loader',
    svg: (
      <Fragment>
        <path
          d='M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50'
          fill='#0e64ff'
          stroke='none'
        >
          <animateTransform
            attributeName='transform'
            type='rotate'
            dur='0.9900990099009901s'
            repeatCount='indefinite'
            keyTimes='0;1'
            values='0 50 51;360 50 51'
          />
        </path>
      </Fragment>
    ),
    viewBox: '0 0 100 100',
    width: '15px',
    height: '15px'
  }
]
