import React, { useEffect, useState } from 'react'
import { TabHeader, TabHeaderWrapper, TabPane, TabWrapper } from './styles'
import get from 'lodash/get'
import map from 'lodash/map'

const Tabs = (props) => {
  const {
    tabData,
    defaultActiveKey,
    tabPosition,
    className,
    onTabClick,
    headerPosition
  } = props
  const subtractBy = 1
  const [activeTabKey, setActiveTab] = useState(defaultActiveKey)

  useEffect(() => {
    setActiveTab(defaultActiveKey)
  }, [defaultActiveKey])

  const renderActiveTab = (event, activeTabKey) => {
    setActiveTab(activeTabKey)
    onTabClick && onTabClick(activeTabKey)
  }

  return (
    <TabWrapper tabPosition={tabPosition} className={className}>
      {/* Tab Header */}
      <TabHeaderWrapper
        tabPosition={tabPosition}
        headerPosition={headerPosition}
        className='makeui-tab-listitem-wrapper'
      >
        {map(tabData, (eachTab) => {
          return (
            <TabHeader
              key={eachTab.key}
              tabPosition={tabPosition}
              onClick={(e) => renderActiveTab(e, eachTab.key)}
              isActiveTab={activeTabKey === eachTab.key}
            >
              {get(eachTab, 'tabTitle')}
            </TabHeader>
          )
        })}
      </TabHeaderWrapper>

      {/* Tab Body */}
      <TabPane tabPosition={tabPosition} className='make-tab-listitem-data'>
        {get(tabData[activeTabKey - subtractBy], 'component')}
      </TabPane>
    </TabWrapper>
  )
}

Tabs.defaultProps = {
  tabPosition: 'top',
  className: 'makeui-tab-container',
  headerPosition: 'space-between'
}

export default Tabs
