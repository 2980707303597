import React, { useState } from 'react'
import {
  ButtonWrapper,
  SwitchButtonTabWrapper,
  ToggleBtnIconWrapper
} from './styles'
import PropTypes from 'prop-types'

const SwitchButtonTab = ({
  options,
  onChange,
  props,
  activeIndex,
  backgroundColor,
  unstyled,
  className
}) => {
  const [isActiveIndex, setIsActiveIndex] = useState(activeIndex)

  const handleChange = (index) => {
    setIsActiveIndex(index)
    if (onChange && typeof onChange === 'function') {
      onChange(options[index], index)
    }
  }
  return (
    <SwitchButtonTabWrapper
      backgroundColor={backgroundColor}
      className={`switch-button-wrapper ${className}`}
    >
      {options &&
        Array.isArray(options) &&
        options.length > 1 &&
        options.map((item, index) => {
          return (
            <div
              key={item.value}
              className={`flex-1 ${item.isDisabled ? 'disabled' : ''}`}
            >
              <ButtonWrapper
                type='button'
                className={`single-option ${
                  index === isActiveIndex ? 'active' : ''
                }`}
                onClick={() => handleChange(index)}
                isIcon={Boolean(item.icon)}
                unstyled={unstyled}
                {...props}
              >
                {item.icon && (
                  <ToggleBtnIconWrapper
                    isLabel={Boolean(item.label)}
                    className='icon-wrapper'
                  >
                    {item.icon}
                  </ToggleBtnIconWrapper>
                )}
                <span>{item.label}</span>
              </ButtonWrapper>
            </div>
          )
        })}
    </SwitchButtonTabWrapper>
  )
}

SwitchButtonTab.propTypes = {
  onChange: PropTypes.func,
  props: PropTypes.any,
  activeIndex: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
  unstyled: PropTypes.bool,
  className: PropTypes.string
}

SwitchButtonTab.defaultProps = {
  options: [
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' }
  ],
  /* this will return two values i.e the selected object and the index of the object */
  onChange: undefined,
  props: undefined,
  value: '',
  unstyled: false,
  activeIndex: -1,
  className: ''
}

export default SwitchButtonTab
