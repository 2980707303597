import firebase from "firebase";

const isProduction = process.env.REACT_APP_DEPLOY_TO === "live";
const firebaseConfig = isProduction
  ? {
    apiKey: "AIzaSyBmjkqXXeAEX1A_qnINtRywO4xUt70Hxc8",
    authDomain: "makestories-202705.firebaseapp.com",
    databaseURL: "https://makestories-202705.firebaseio.com",
    projectId: "makestories-202705",
    storageBucket: "cdn-storyasset-link",
    messagingSenderId: "450976295561",
    clientId:
      "450976295561-urpqcifn766lu9bvoagkc9srq4ovkeia.apps.googleusercontent.com",
  }
  : {
    apiKey: "AIzaSyBmjkqXXeAEX1A_qnINtRywO4xUt70Hxc8",
    authDomain: "makestories-202705.firebaseapp.com",
    databaseURL: "https://makestories-202705.firebaseio.com",
    projectId: "makestories-202705",
    storageBucket: "cdn-storyasset-link",
    messagingSenderId: "450976295561",
    clientId:
      "450976295561-urpqcifn766lu9bvoagkc9srq4ovkeia.apps.googleusercontent.com",
  };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
