import get from 'lodash/get'

import styled from 'styled-components'
import { tabs } from '../../utils/styles/theme'

/**
 * Define the styling of the tabs based on the position passed
 *  position placement of the tab
 *  flex-direction styling
 */
const getTabDirection = (position) => {
  switch (position) {
    case 'top':
      return 'column'
    case 'bottom':
      return 'column-reverse'
    case 'left':
      return 'row'
    default:
      return 'row-reverse'
  }
}

const getHeaderDirection = (position) => {
  switch (position) {
    case 'start':
      return 'flex-start'
    case 'end':
      return 'flex-end'
    case 'center':
      return 'center'
    default:
      return 'space-between'
  }
}

export const TabHeaderWrapper = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  display: ${(props) =>
    (props.tabPosition === 'left' || props.tabPosition === 'right') && 'flex'};
  flex-direction: ${(props) =>
    (props.tabPosition === 'left' || props.tabPosition === 'right') &&
    'column'};
  display: flex;
  justify-content: ${(props) =>
    getHeaderDirection(get(props, 'headerPosition', 'space-between'))};
`

export const TabHeader = styled.li`
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  flex: ${({ tabPosition }) =>
    tabPosition === 'left' || tabPosition === 'right' ? '0' : 'auto'};
  text-align: center;
  padding: ${({ tabPosition }) =>
    tabPosition === 'left' || tabPosition === 'right'
      ? '12px 5px'
      : '12px 5px'};
  color: ${({ isActiveTab }) =>
    isActiveTab ? tabs.TEXT_COLOR_ACTIVE : tabs.TEXT_COLOR_INACTIVE};

  ::before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 2px;
    width: 0px;
    background-color: rgb(14, 100, 255);
    content: '';
    transition: all 0.3s ease 0s;
    margin: 0px auto;
    ${({ isActiveTab }) =>
      isActiveTab &&
      `width: 100%;
       background: ${tabs.TEXT_COLOR_ACTIVE};`};
  }
  :hover::before {
    width: 95%;
    background: ${tabs.TEXT_COLOR_ACTIVE};
  }
`

export const TabPane = styled.div`
  padding: ${({ tabPosition }) =>
    tabPosition === 'left' || tabPosition === 'right' ? '0 10px' : '1.25rem'};
`

export const TabWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: ${(props) =>
    getTabDirection(get(props, 'tabPosition', 'top'))};
`

/*
 * Get the border styling based on the position passed
 * Theme App Theme
 * placement of the tab
 * isActiveTab States if the current tab is active
 * border styling

const getBorderPositon = (position, isActiveTab) => {
  const value = `${
    isActiveTab
      ? `2px solid ${tabs.ACTIVE_BORDER}; font-weight: 500; letter-spacing: 1px; `
      : ''
  }`

  switch (position) {
    case 'top':
      return `border-bottom: ${value}`
    case 'bottom':
      return `border-top: ${value}`
    case 'left':
      return `border-right: ${value}`
    case 'right':
      return `border-left: ${value}`
    default:
      return `border-top: ${value}`
  }
}
 */
