import styled from 'styled-components'
import { sliderWithInput } from '../../utils/styles/theme'

const SliderWithInputWrapper = styled.div`
  margin: 5px 0;
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      margin: 0;
    }
  }

  .make-ui-slider-label {
    color: rgb(79, 79, 79);
    color: #4f4f4f;
    display: block;
    font-size: 14px;
    line-height: 22px;
    margin: 5px 0 5px 0;
    min-width: 10px;
    font-weight: normal;
    text-transform: capitalize;
  }
  .make-ui-slider-input {
    width: 80px;
  }

  .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 150px;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rc-slider-handle {
    background: #0e64ff;
    border: 1px solid #0e64ff;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    transform: translateX(-20%) !important;
  }

  .rc-slider-track {
    background-color: ${sliderWithInput.SLIDER_COLOR};
  }

  .rc-slider-handle:hover {
    border-color: ${sliderWithInput.SLIDER_COLOR};
  }

  .rc-slider-handle:focus {
    border-color: ${sliderWithInput.SLIDER_COLOR};
    outline: none;
    -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
  }
`
export { SliderWithInputWrapper }
