import styled, { css } from 'styled-components'
import { ELEMENT_SPACE, input, LABEL_MARGIN } from '../../utils/styles/theme'
import { TEXT_INPUT } from './constants'

const Wrapper = styled.div`
  margin: ${ELEMENT_SPACE}px 0;
  ${({ horizontal }) =>
    horizontal
      ? css`
          display: flex;
          align-items: center;
        `
      : css`
          display: flex;
          width: 100%;
          flex-direction: column;
        `}
  label {
    font-size: 14px;
    line-height: 22px;
    ${({ horizontal }) =>
      horizontal
        ? css`
            margin: 0 10px 0 0;
          `
        : css`
            ${LABEL_MARGIN}
          `}
    color: #4f4f4f;
    display: flex;
    align-items: center;
  }
`
const TextInput = styled.input`
  padding: 0;
  margin: 0;
  border: none !important;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;

  ${({ size }) => {
    switch (size) {
      case TEXT_INPUT.SIZE.BIG:
        return css`
          font-size: 16px;
        `
      default:
        return css``
    }
  }}
  :placeholder-shown {
    text-overflow: ellipsis;
  }
  &:focus {
    border: none;
  }
`

const InputSection = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  box-sizing: border-box;

  &.make-ui-text-input-wrapper-focus {
    border: 1px solid #0e64ff;
  }
  ::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
    box-sizing: border-box;
  }

  .make-ui-text-input-prefix {
    margin-right: 4px;
  }

  .make-ui-text-input-suffix {
    margin-left: 4px;
  }

  .make-ui-text-input-prefix,
  .make-ui-text-input-suffix {
    display: flex;
    flex: none;
    align-items: center;
    box-sizing: border-box;
    /* opacity: 0.4; */
  }

  ${({ size }) => {
    switch (size) {
      case TEXT_INPUT.SIZE.BIG: {
        return css`
          padding: 6.5px 11px;
          font-size: 16px;
        `
      }
      case TEXT_INPUT.SIZE.SMALL: {
        return css`
          padding: 0 7px;
        `
      }
      default: {
        return css``
      }
    }
  }}
  ::focus {
    border: 1px solid ${input.FOCUS_COLOR};
  }
`

const TextAreaWrapper = styled.textarea`
  padding: 0;
  margin: 0;
  border: none !important;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border-radius: 2px;
  transition: all 0.3s;

  ${({ size }) => {
    switch (size) {
      case TEXT_INPUT.SIZE.BIG:
        return css`
          font-size: 16px;
        `
      default:
        return css``
    }
  }}
  :placeholder-shown {
    text-overflow: ellipsis;
  }
  &:focus {
    border: none;
  }
`
export { Wrapper, TextInput, InputSection, TextAreaWrapper }
