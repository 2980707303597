import styled from 'styled-components'
import { accordian, ELEMENT_SPACE } from '../../utils/styles/theme'

const AccordianWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${ELEMENT_SPACE}px 0;
`
const PanelWrapper = styled.div`
  box-sizing: border-box;
  height: auto;
  padding: 0 1.25rem;
  margin: 0 -1.25rem;
  border-bottom: 1px solid rgba(237, 237, 237, 0.5);
  &.active {
    background-color: ${({ background }) => background || '#fafafa'};
  }
  .accordian-header {
    cursor: pointer;
    width: 100%;
    position: relative;
    padding: 15px 0;
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
  }
  .accordian-header.active {
    color: ${accordian.ACTIVE_COLOR};
  }
  .accordian-content {
    max-height: 0;
    display: none;
    margin: ${ELEMENT_SPACE}px 0;
  }
  .accordian-content.active {
    display: block;
    max-height: initial;
  }
  .panel-flex {
    display: flex;
  }
`
const ImgWrapper = styled.img`
  width: '20px';
  height: '20px';
  ${(props) =>
    props.isActive &&
    `transform: rotate(
    180deg);`}
`

export { AccordianWrapper, PanelWrapper, ImgWrapper }
