import styled from 'styled-components'
import { colors, dropdown } from '../../utils/styles/theme'

export const SelectWrapper = styled.div`
  margin: 15px 0;
  box-sizing: border-box;
  label {
    color: #4f4f4f;
    display: block;
    font-size: 14px;
    line-height: 22px;
    margin: 5px 0 10px 0;
    min-width: 10px;
    font-weight: normal;
  }
  .custom-select {
    font-size: 13px;
    font-weight: 400;
    box-sizing: border-box;
    min-width: 100px;
    height: auto;
    .custom-select__control {
      box-shadow: none;
      cursor: pointer;
      &:hover {
        border-color: ${dropdown.HOVER_COLOR};
      }
      .custom-select__indicator-separator {
        display: none;
      }
    }
    .custom-select__indicator {
      padding: 4px;
    }
    .custom-select__control {
      background: #ffffff;
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 2px;

      min-height: 32px;
      .custom-select__indicator-separator {
        display: none;
      }
    }
    .custom-select__menu {
      z-index: 999999;
      cursor: pointer;
    }

    .custom-select__ValueContainer {
      padding: 0 4px;
    }

    .custom-select__indicatorContainer {
      padding: 0;
      /* .custom-select__single-value {
        overflow: auto;
      } */
    }

    .custom-select--is-disabled {
      color: ${colors.DISABLED_TEXT};
      background-color: #f0f3f4;
    }
  }
`
