import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
  contextMenu
} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import './MenuV2.css'

const ReactContexify = {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
  contextMenu
}

export default ReactContexify
