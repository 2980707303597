import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { ModalWrapper, Overlay, Header, Body, Footer } from './styles'
import Icon from '../Icon'

const Modal = ({
  className,
  children,
  showCloseIcon,
  onClose,
  width,
  visible,
  onEnterHandler,
  title,
  footer,
  maskClosable
}) => {
  /* close modal on escape button click */
  const handleEscapeKey = (e) => {
    if (e.code === 'Escape') {
      onClose()
    } else if (e.code === 'Enter') {
      onEnterHandler && onEnterHandler()
    }
  }

  /* Fuction to handle body scrollbar when modal is opened/close { action } hide/show scroll bar */
  const handleBodyScroll = ({ action }) => {
    document.body.style.cssText = `overflow: ${
      action === 'open' ? 'hidden' : ''
    } `
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('keydown', handleEscapeKey)
      handleBodyScroll({ action: 'open' })
      return () => {
        document.removeEventListener('keydown', handleEscapeKey)
        handleBodyScroll({ action: 'close' })
      }
    }
  })

  return visible ? (
    <Fragment>
      <Overlay onClick={maskClosable ? onClose : null} />
      <ModalWrapper className={className} width={width}>
        <Header className='custom-cross-class'>
          <header>{title}</header>
          {showCloseIcon ? (
            <Icon
              name='close'
              onClick={onClose}
              style={{ opacity: 0.4, cursor: 'pointer' }}
            />
          ) : null}
        </Header>

        <Body className='make-ui-modal-body'>{children}</Body>
        {footer?.length > 0 ? (
          <Footer>
            {footer.map((component, _idx) => (
              <Fragment key={_idx}>{component}</Fragment>
            ))}
          </Footer>
        ) : null}
      </ModalWrapper>
    </Fragment>
  ) : null
}
Modal.prototype = {
  /* Callback fired when the component requests to be closed */
  onClose: PropTypes.func,
  /* Modal class name */
  className: PropTypes.string,
  /* Children to be rendered inside modal */
  children: PropTypes.any,
  /* If true, close icon is shown */
  showCloseIcon: PropTypes.bool,
  /* Width of the modal */
  width: PropTypes.string | undefined,
  /* If true, modal is shown */
  visible: PropTypes.bool,
  /* callback called on enter */
  onEnterHandler: PropTypes.any,
  /* Modal Title */
  title: PropTypes.any
}

Modal.defaultProps = {
  className: 'makeui-modal',
  width: '100%',
  title: 'Title',
  footer: [],
  maskClosable: false,
  showCloseIcon: true,
  onClose: () => {}
}

export default Modal
