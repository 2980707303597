import { useEffect } from 'react'

export const useClickOutside = (domNode, handler) => {
  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler()
      }
    }
    document.addEventListener('mousedown', maybeHandler)
    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  })

  return domNode
}
