import styled, { css } from 'styled-components'
import { buttonTheme, colorPalatte } from '../../utils/styles/theme'

export const getStyles = (variant, style) => {
  const variations = {
    default: {
      backgroundcolor: colorPalatte.WHITE,
      color: colorPalatte.BLACK,
      textColor: buttonTheme.PRIMARY,
      borderColor: buttonTheme.PRIMARY
    },
    primary: {
      backgroundcolor: buttonTheme.PRIMARY,
      color: colorPalatte.WHITE,
      bgHoverColor: buttonTheme.HOVER,
      bgActiveColor: buttonTheme.HOVER,
      bgDisableColor: buttonTheme.DEFAULTDISABLE
    },
    alternate: {
      backgroundcolor: buttonTheme.SECONDARY,
      color: buttonTheme.PRIMARY,
      bgHoverColor: buttonTheme.ALTERNATEHOVER,
      bgActiveColor: buttonTheme.ALTERNATEACTIVE,
      bgDisableColor: buttonTheme.SECONDARY
    },
    icon: {
      backgroundcolor: buttonTheme.PRIMARY,
      color: colorPalatte.BLACK,
      bgHoverColor: buttonTheme.HOVER,
      bgActiveColor: buttonTheme.PRIMARY,
      bgDisableColor: colorPalatte.LIGHT_GREY
    },
    group: {
      backgroundcolor: buttonTheme.PRIMARY,
      color: colorPalatte.BLACK,
      bgHoverColor: buttonTheme.HOVER,
      bgActiveColor: buttonTheme.PRIMARY,
      bgDisableColor: colorPalatte.LIGHT_GREY
    }
  }

  return (variations[variant] && variations[variant][style]) || 'none'
}

const commonbuttoncss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  border-radius: 4px;
  min-width: 64px;
`

const renderVariant = (color) => {
  switch (color) {
    case 'group':
      return groupVariant
    case 'icon':
      return iconVariant
    case 'primary':
    case 'alternate':
      return containedVariant
    default:
      return ``
  }
}
const unstyledVariant = css`
  background-color: transparent !important;
  border: none !important;
`

const iconVariant = css`
  min-width: 0;
  &:hover,
  &--active {
    opacity: 0.7;
  }
`

const onlyIconVariant = css`
  ${({ variant, child, unstyled }) =>
    variant === 'icon' &&
    !child &&
    unstyled &&
    css`
      padding: 5px;
    `}
`

const groupVariant = css`
  /* Inside .button class & on the same level of  &.button--default, button--large, &.button--medium, &.button--small  */
  background-color: #ffffff;
  min-width: auto;
  padding: 10px;
  border-radius: 0;
  &.button--default,
  &.button--medium,
  &.button--small {
    line-height: normal;
    padding: 10px;
  }
  &.button--large {
    line-height: normal;
    padding: 20px;
  }

  &--active,
  :hover {
    opacity: 1;
    background-color: #ecf3ff;
    color: #0e64ff;
    .button--text .icon-section {
      svg {
        fill: #0e64ff;
      }
    }
  }
`

const containedVariant = css`
  border: none;
`

const ButtonGroup = styled.div`
  display: inline-flex;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  background: #ffffff;
  min-width: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  /* align-items: center */
  .button {
    ${commonbuttoncss}
    color: ${({ variant }) => getStyles(variant, 'color')};
    background-color: ${({ variant }) => getStyles(variant, 'backgroundcolor')};
    border: ${({ variant }) => variant === 'default' && '1px solid #e7e7e7'};

    /* SIZES */
    &.button--small {
      font-size: 0.8125rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      padding: 4px 10px;
      ${onlyIconVariant}
    }

    &.button--default,
    &.button--medium {
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      padding: 6px 16px;
      ${onlyIconVariant}
    }

    &.button--large {
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      padding: 6px 16px;
      ${onlyIconVariant}
    }

    &--active {
      .button--text {
        white-space: nowrap !important;
        z-index: 2;
      }

      &:after {
        background-color: #000000;
        opacity: 0.15;
        z-index: 0;
      }
    }
    /* Button states */
    &.button--saving,
    &.button--saved {
      pointer-events: none;

      .button--text {
        position: relative;
        padding-left: 20px;

        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: -6px;
          width: 18px;
          height: 18px;
          background-image: url('/img/circle.svg');
          background-repeat: no-repeat;
        }
      }
    }
    &.button--saving {
      .button--text {
        &:after {
          content: '';
          position: absolute;
          top: 4px;
          left: -6px;
          background-image: url('/img/ellipse.svg');
          background-repeat: no-repeat;
          width: 18px;
          height: 18px;
          z-index: 1;
          animation: rotate 1s linear infinite;
        }
      }
    }
    &.button--saved {
      .button--text {
        :before {
          top: 0px;
          background-image: url('/img/tick.svg');
          width: 100%;
          height: 100%;
        }
      }
    }

    &.button--disabled {
      background: ${(props) => getStyles(props.variant, 'bgDisableColor')};
      cursor: not-allowed;
      opacity: 0.5;
      &:after {
        display: none;
      }
    }

    &:after {
      display: none;
    }
    &:hover {
      opacity: 0.7;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .button--text .icon-section {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      svg {
        fill: ${({ variant }) => getStyles(variant, 'color')};
      }
    }
    /* unstyled css */
    ${({ unstyled }) => unstyled && unstyledVariant}
    /* variants */
    ${({ variant }) => renderVariant(variant)}
  }
`

export { ButtonWrapper, ButtonGroup }
