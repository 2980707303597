import React from 'react'
import PropTypes from 'prop-types'
import { SelectWrapper } from './styles'
import Select from 'react-select'

const CustomSelect = ({
  label,
  options,
  handleChange,
  placeholder,
  isClearable,
  disabled,
  customProps,
  selectedOption,
  className,
  handleSearch
}) => {
  let finalSelectedOption = selectedOption
  if (typeof selectedOption !== 'object') {
    finalSelectedOption = options.find(
      (option) => option.value === selectedOption
    )
  }
  return (
    <SelectWrapper className={className}>
      {label ? <label className='make-ui-dropdown-label'>{label}</label> : null}
      <Select
        classNamePrefix='custom-select'
        className='custom-select'
        options={options}
        menuPlacement='auto'
        placeholder={placeholder}
        onChange={handleChange}
        isClearable={isClearable}
        isDisabled={disabled}
        value={finalSelectedOption}
        onSearch={handleSearch}
        {...customProps}
      />
    </SelectWrapper>
  )
}
CustomSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectedOption: PropTypes.any
}

CustomSelect.defaultProps = {
  options: [],
  placeholder: '',
  isClearable: false,
  disabled: false
}

export default CustomSelect
