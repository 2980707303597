import styled, { css } from 'styled-components'
import { ELEMENT_SPACE, switchTheme } from '../../utils/styles/theme'

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${ELEMENT_SPACE}px 0;
  .flex-1 {
    flex: 1;
  }

  .makeui-switch-labeltext {
    font-size: 14px;
    line-height: 22px;
    color: rgb(79, 79, 79);
    word-break: break-word;
  }
`

const SwitchInner = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0;
  .makeui-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    height: 14px;
    border-radius: 20px;
    width: 36px;

    .makeui-slider-switch-handle {
      left: 0px;
      position: absolute;
      box-shadow: 0 1px 3px rgb(0 0 0 / 21%);
      width: 20px;
      height: 20px;
      background: #fff;
      top: -3px;
      border-radius: 20px;
      transition: all 0.2s ease-in-out;
      ${({ wait }) =>
        wait
          ? css`
              cursor: not-allowed;
              opacity: 0.7;
            `
          : ''}
    }
  }
  input:checked + .makeui-slider {
    background-color: #e6efff;
  }

  input:checked + .makeui-slider {
    .makeui-slider-switch-handle {
      left: calc(100% - 20px);
      background-color: ${switchTheme.PRIMARY};
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  input:disabled + .makeui-slider {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
  }
`

export { SwitchInner, SwitchWrapper }
