import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomcheckBox, GroupWrapper, Container } from './styles'

const CheckBox = ({
  defaultChecked,
  disabled,
  className,
  value,
  handleChange,
  label,
  id
}) => {
  const checked = value || defaultChecked
  const handleCheckboxChange = (e) => {
    if (handleChange && typeof handleChange === 'function')
      handleChange(e.target.checked)
  }

  return (
    <CustomcheckBox className={`${className} ${disabled ? 'disabled' : ''}`}>
      <input
        type='checkbox'
        checked={checked}
        disabled={disabled}
        value={value}
        id={id}
        onChange={handleCheckboxChange}
      />

      <label htmlFor={id}>{label}</label>
    </CustomcheckBox>
  )
}
CheckBox.propTypes = {
  /*  sets whether the checkbox must be checked or unchecked when the component is load initially */

  defaultChecked: PropTypes.bool,
  /* determines whether the checkbox can be checked or unchecked */

  disabled: PropTypes.bool,
  /* The label of the checkbox */

  children: PropTypes.any,
  /* Specifies list of class names */

  className: PropTypes.string,
  /* Callback function which is fired when user checks or unchecks a checkbox */

  handleChange: PropTypes.func,
  /* Determines the value associated with the checkbox */

  value: PropTypes.any
}

CheckBox.defaultProps = {
  defaultChecked: false,
  disabled: false,
  className: '',
  handleChange: (e) => e
}

export default CheckBox

export function CheckBoxGroup({
  options,
  handleChange,
  horizontal,
  variant,
  className,
  label
}) {
  const [isCheckboxSelected, setCheckboxSelected] = useState(options)
  const groupChange = (e, id) => {
    const copy = isCheckboxSelected.map((data) =>
      data.id === id ? { ...data, isChecked: e } : data
    )
    setCheckboxSelected(copy)
    handleChange(copy)
  }

  return (
    <Container>
      {label && <label>{label}</label>}
      <GroupWrapper
        horizontal={horizontal}
        variant={variant}
        className={className}
      >
        {isCheckboxSelected.map((data) => (
          <CheckBox
            value={data.isChecked}
            label={data.label}
            id={data.id}
            key={data.id}
            handleChange={(e) => groupChange(e, data.id)}
          />
        ))}
      </GroupWrapper>
    </Container>
  )
}

CheckBox.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  horizontal: PropTypes.bool,
  variant: PropTypes.string
}

CheckBox.defaultProps = {
  className: 'makeui-checkbox-group',
  variant: 'default',
  horizontal: true,
  options: [],
  handleChange: () => {}
}
