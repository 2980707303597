import { AuthService } from "./modules/firebase/firebase.service";
import { storeSyncMiddleware, getUserId, getWorkspaceId } from "./modules/store/store.service";

const authService = new AuthService();

export {
	AuthService,
	storeSyncMiddleware,
	getUserId,
	getWorkspaceId,
    authService,
};
