import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SwitchWrapper, SwitchInner } from './styles'
import Icon from '../Icon'

const Switch = ({
  labelText,
  defaultChecked,
  disabled,
  handleChange,
  loading,
  className
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  useEffect(() => {
    setChecked(defaultChecked)
  }, [defaultChecked])

  const handleSwitchChange = (e) => {
    handleChange(e.target.checked)
    setChecked(e.target.checked)
  }

  return (
    <SwitchWrapper className={className}>
      <label className='makeui-switch-labeltext flex-1'>{labelText}</label>
      <SwitchInner className='makeui-switch-inner' wait={loading}>
        <input
          type='checkbox'
          checked={checked}
          disabled={disabled}
          onChange={handleSwitchChange}
          className='makeui-switch-input'
        />
        <span className='makeui-slider'>
          <div className='makeui-slider-switch-handle'>
            {loading && (
              <Icon
                name='loader'
                style={{
                  margin: 'auto',
                  background: 'none',
                  display: 'block',
                  shapeRendering: 'auto'
                }}
              />
            )}
          </div>
        </span>
      </SwitchInner>
    </SwitchWrapper>
  )
}
Switch.propTypes = {
  labelText: PropTypes.string,
  handleChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  loading: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

Switch.defaultProps = {
  labelText: '',
  defaultChecked: false,
  disabled: false,
  className: '',
  loading: false
}

export default Switch
