import React, { useRef, useState } from 'react'
import {
  TooltipWrapper,
  TooltipTarget,
  CenterContainer,
  TooltipBox
} from './Tooltip.styled'
import PropTypes from 'prop-types'

/* wrap the tooltip componennt with block elements else inline styles will be applied */

const Tooltip = ({ position, text, children, background, className }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const targetRef = useRef(null)
  const showTooltip = isHovered || isFocused

  const handleClick = (e) => {
    e.preventDefault()
    if (targetRef.current) {
      targetRef.current.blur()
    }
  }

  return (
    <TooltipWrapper className={className}>
      <TooltipTarget
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onClick={handleClick}
        ref={targetRef}
        showOnFocus={isFocused}
      >
        {children}
      </TooltipTarget>
      {showTooltip && (
        <CenterContainer position={position}>
          <TooltipBox background={background} position={position}>
            {text}
          </TooltipBox>
        </CenterContainer>
      )}
    </TooltipWrapper>
  )
}

Tooltip.propTypes = {
  position: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  text: PropTypes.string,
  children: PropTypes.any,
  background: PropTypes.string,
  className: PropTypes.string
}
Tooltip.defaultProps = {
  postion: 'bottom',
  text: '',
  background: '#fff',
  className: ''
}
export default Tooltip
